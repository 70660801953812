import { Space, Tag } from 'antd'
import DeviceIcons from '@components/Device/Icons'
import { CustomDescriptionType } from '@/types/IDescriptions'
import dayjs from 'dayjs'
import humanizeSizes from '@helpers/humanizeSizes'
import { DATEFORMAT } from '@constants/dateformat'
import { IDevice, IDeviceContract } from '@/types/IDevice'
import humanizeDurations from '@helpers/humanizeDurations'
import AvatarSprite from '@components/common/AvatarSprite'
import DeviceInterfaces from '../DeviceInterfaces'
import DeviceStateTag from '../DeviceStateTag'
import DeviceSecrets from '../DeviceSecrets'
import DeviceParent from '../DeviceParent'
import TextMarkdown from '@components/common/TextMarkdown'
import { CompanyTag } from '@components/Ticket/Table'

const turnArray = (item: any) => (Array.isArray(item) ? item : [item])

interface DeviceContractProps<T extends keyof IDeviceContract> {
  data: IDeviceContract[T]
  item: IDeviceContract
}

const Date = ({ date }: { date: Date }) => {
  return <span>{date ? dayjs(date).format(DATEFORMAT) : null}</span>
}

export const overviewColumn: CustomDescriptionType<IDevice>[] = [
  {
    span: 1,
    label: 'UUID',
    key: 'uuid'
  },
  {
    label: 'DOMAIN',
    key: 'domain'
  },
  {
    label: 'STATE',
    key: 'state',
    span: 1,
    render: ({ data }: { data: IDevice['state'] }) => {
      return <DeviceStateTag state={data} />
    }
  },
  {
    label: 'UPTIME',
    key: 'uptime',
    span: 1,
    render: ({ data }) => <span>{data ? humanizeDurations(data) : null}</span>
  },
  {
    label: 'DC',
    key: 'dc',
    render: ({ data }) => (data ? <span> {data.shortname} </span> : null)
  },
  {
    label: 'PARENT',
    key: 'parent',
    render: ({ data }) => (
      <span>{data ? <DeviceParent parent={data} /> : null}</span>
    )
  },
  {
    label: 'COMPANY',
    key: 'company',
    span: 1,
    render: ({ data }) => (
      <CompanyTag company={data} />
    )
  },
  {
    label: 'OS',
    key: 'os',
    render: ({ data }) => (
      <span>
        {data ? (
          <>
            <DeviceIcons kind={data.kind} /> {data.name}
          </>
        ) : null}
      </span>
    )
  },
  {
    label: 'CPU',
    key: 'cpu',
    span: 1
  },
  {
    label: 'MEMORY',
    key: 'mem',
    span: 1
  },
  {
    label: 'ADMIN',
    key: 'uuid',
    span: 2,
    render: ({ data }: { data: IDevice['uuid'] }) => (
      <DeviceSecrets uuid={data} />
    )
  },
  {
    label: 'INTERFACES',
    key: 'interfaces',
    span: 1,
    render: ({ data }: { data: IDevice['interfaces'] }) => {
      const item = turnArray(data)
      return (
        <Space wrap>
          <DeviceInterfaces interfaces={item} />
        </Space>
      )
    }
  },
  {
    label: 'ACCESS',
    key: 'access',
    span: 1,
    render: ({ data }) => (
      <TextMarkdown text={data} />
    )
  },
  {
    label: 'DISKS',
    key: 'disks',
    render: ({ data, item }: { item: IDevice; data: IDevice['disks'] }) => (
      <>
        {data
          ? data.map((disk, index) => (
            <div key={index}>
              <span>
                {humanizeSizes(
                  disk[item.type === 'BAREMETAL' ? 'partitions' : 'size']
                )}
              </span>
              <br />
            </div>
          ))
          : null}
      </>
    )
  },
  {
    label: 'ISPOC',
    key: 'is_poc'
  },
  {
    label: 'POCEXPIREAT',
    key: 'poc_expire_at'
  },
  {
    label: 'CREATEDAT',
    key: 'created_at',
    render: ({ data }) => (data ? dayjs(data).format(DATEFORMAT) : null)
  },
  {
    label: 'UPDATEDAT',
    key: 'updated_at',
    render: ({ data }) => <Date date={data} />
  },
  {
    label: 'DELETEDAT',
    key: 'deleted_at',
    render: ({ data }) =>
      data ? <span>{dayjs(data).format(DATEFORMAT)} </span> : null
  }
]

const render = ({ data }: { data: IDeviceContract['created_by'] }) => {
  return <>{data ? <AvatarSprite user={data} /> : null}</>
}

export const contractColumns = [
  {
    label: 'ISPOC',
    key: 'is_poc'
  },
  {
    label: 'TYPE',
    key: 'type'
  },
  {
    label: 'CPU',
    key: 'cpu'
  },
  {
    label: 'Mem',
    key: 'mem'
  },
  {
    label: 'OPERATINGSYSTEM',
    key: 'os'
  },
  {
    label: 'BACKUP',
    key: 'backup'
  },
  {
    label: 'FIREWALL',
    key: 'firewall'
  },
  {
    label: 'SERVICES',
    key: 'services'
  },
  {
    label: 'DISKS',
    key: 'disks',
    render: ({ data }: { data: DeviceContractProps<'disks'> }) => {
      return (
        <Space>
          {data
            ? data.map(disk => (
              <Tag color="blue-inverse">
                {disk.type}: {disk.size}Gb
              </Tag>
            ))
            : null}
        </Space>
      )
    }
  },
  {
    label: 'OBS',
    key: 'obs'
  },
  {
    label: 'CREATEBY',
    key: 'created_by',
    render
  },
  {
    label: 'UPDATEBY',
    key: 'updated_by',
    render
  },
  {
    label: 'CREATEDAT',
    key: 'created_at',
    render: ({ data }: DeviceContractProps<'created_at'>) =>
      data ? dayjs(data).format(DATEFORMAT) : null
  },
  {
    label: 'UPDATEDAT',
    key: 'updated_at',
    render: ({ data }: DeviceContractProps<'updated_at'>) => (
      <Date date={data} />
    )
  },
  {
    label: 'DELETEDAT',
    key: 'deleted_at',
    render: ({ data }: DeviceContractProps<'deleted_at'>) =>
      data ? <span>{dayjs(data).format(DATEFORMAT)} </span> : null
  }
]
