import {
  Form,
  Flex,
  Row,
  Card,
  Col,
  Divider,
  InputNumber,
  Space,
  Input
} from 'antd'
import {
  AddItemButton,
  FormItemActions,
  ProductItemInput,
  ProductOverrideInput,
  ProductPreandPostFixInputs,
  ProductPriceInput,
  ProductRequiredCheckbox,
  ProductTypeSelect,
  DependentOnSelect
} from '../ProductFormItems'
import {
  ProductFormListCard,
  ProductFormListFieldsProps,
  ProductFormListProps
} from '@/types/IProduct'
import ProductMinorMaxInput from '../ProductMinorMaxInput'
import { useTranslation } from 'react-i18next'
import { NamePath } from 'antd/es/form/interface'
import InputWithForm from '@components/common/InputWithForm'
import Icon from '@components/common/Icon'
import { TFunction } from 'i18next'

const ProductItemTitle = ({ name, path }: ProductFormListFieldsProps) => {
  return (
    <Row
      align="middle"
      justify="space-between"
      gutter={[20, 20]}
      style={{ marginTop: '20px' }}
    >
      <ProductItemInput name={[name, 'item']} />
      <ProductOverrideInput name={name} />
      <ProductIconNameInput path={path} name={name} />
      <ProductTypeSelect path={path} name={name} />
      <ProductRequiredCheckbox name={name} />
      <DependentOnSelect name={name} path={path} />
    </Row>
  )
}

const iconTooltip = (t: TFunction) => `
      ${t('ICONSAVALIABLEONHTTPSFONTAWESOMECOM')} ->
      ${t('INSERTTHELASTNAMEOFTHEICON')} ->
      ${t('EXFAMEMORY')}
  `

const ProductIconNameInput = ({
  path,
  name
}: {
  path: NamePath
  name: NamePath
}) => {
  const { t } = useTranslation()
  return (
    <Col span={6}>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const item = path ? getFieldValue(path) : null
          return (
            <InputWithForm
              name={[name, 'icon_name']}
              tooltip={iconTooltip(t)}
              label={
                <Space>
                  <Icon name={`fa - light ${item.icon_name}`} />
                  {t('ICONNAME')}
                </Space>
              }
              shouldUpdate
            />
          )
        }}
      </Form.Item>
    </Col>
  )
}

const ProductStepInput = ({ path, name }: ProductFormListFieldsProps) => {
  const { t } = useTranslation()
  return (
    <Col span={3}>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const item = path ? getFieldValue(path) : null
          return (
            <Form.Item name={[name, 'step']} label={t('STEPS')} shouldUpdate>
              <InputNumber disabled={item.type !== 'ITEM'} min={0} step={0} />
            </Form.Item>
          )
        }}
      </Form.Item>
    </Col>
  )
}

const ProductDescriptionInput = ({
  path,
  name
}: ProductFormListFieldsProps) => {
  const { t } = useTranslation()
  return (
    <Col span={6}>
      <Form.Item name={[name, 'description']} label={t('DESCRIPTION')}>
        <Input.TextArea />
      </Form.Item>
    </Col>
  )
}
const FormListFields = ({ name, path }: ProductFormListFieldsProps) => {
  return (
    <Row gutter={[12, 4]} justify="start">
      <ProductPriceInput name={[name, 'price']} />
      <ProductMinorMaxInput field={name} path={path} name="min" />
      <ProductMinorMaxInput field={name} path={path} name="max" />
      <ProductMinorMaxInput field={name} path={path} name="default" />
      <ProductPreandPostFixInputs name={name} />
      <ProductStepInput name={name} path={path} />
      <ProductDescriptionInput name={name} path={path} />
    </Row>
  )
}

const FormListItemMap = ({ field, path }: any) => {
  return (
    <Form.List name={[field.name, 'children']}>
      {(fields, actions) => (
        <Row justify="end">
          {fields.length > 0 ? (
            <Flex>
              <div className="vertical-product-divider" />
              <Row>
                <Col span={23}>
                  <Row>
                    {fields.map((item, index) => {
                      return (
                        <FormListSubItem
                          key={index}
                          {...{ actions, field: item, index }}
                          name={['items', field.name, 'children', item.name]}
                        />
                      )
                    })}
                  </Row>
                </Col>
              </Row>
            </Flex>
          ) : null}
          <AddItemButton add={actions.add} span={6} path={path} />
        </Row>
      )}
    </Form.List>
  )
}

const FormListItemCard = ({
  field,
  actions,
  index,
  path,
  ...props
}: ProductFormListCard) => {
  return (
    <Card
      type="inner"
      variant="borderless"
      style={{ width: '100%', borderWidth: '3px', marginTop: '10px' }}
      title={<ProductItemTitle name={field.name} path={path} />}
      extra={<FormItemActions {...{ field, actions, index, path }} />}
      {...props}
    />
  )
}

export const FormListItem = (props: ProductFormListProps) => {
  const { field, actions, index } = props
  const path = ['items', field.name]
  return (
    <FormListItemCard {...{ field, actions, index, path }}>
      <FormListFields name={field.name} path={path} />
      <FormListItemMap field={field} path={path} />
    </FormListItemCard>
  )
}

export const FormListSubItem = (props: ProductFormListProps) => {
  const { field, actions, index, name } = props
  return (
    <FormListItemCard {...{ field, actions, index, path: name }}>
      <FormListFields name={field.name} path={name} />
    </FormListItemCard>
  )
}

const FormList = () => {
  return (
    <Form.List name="items">
      {(fields, actions) => (
        <>
          <Row style={{ width: '95%' }}>
            {fields.map((field, index) => (
              <FormListItem {...{ actions, field, index }} key={index} />
            ))}
          </Row>
          <AddItemButton add={actions.add} />
          <Divider />
        </>
      )}
    </Form.List>
  )
}

export default FormList
