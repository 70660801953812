import type { IProductPod } from '@/types/IProduct'
import Icon from '@components/common/Icon'
import TextMarkdown from '@components/common/TextMarkdown'
import { api } from '@helpers/api'
import {
  Avatar,
  Col,
  Form,
  Modal,
  Row,
  Select,
  SelectProps,
  Space,
  Typography,
  message
} from 'antd'
import Card from 'antd/lib/card/Card'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
const APP_URL = import.meta.env.VITE_OPEN_URL

const IconWithLink = ({ uuid }: { uuid: string }) => {
  return (
    <Avatar
      src={`${APP_URL}/assets/images/marketplace/pods/templates/${uuid}`}
      alt="Pod Template"
      size={20}
    >
      <Icon name="fas fa-user-robot" size={50} />
    </Avatar>
  )
}

type Image = {
  uuid: string
  name: string
}

type Option = SelectProps['options']

const transformImagesToOptions = (images: Image[]): Option => {
  return images.map(image => ({
    value: image.uuid,
    label: (
      <Space>
        <IconWithLink uuid={image.uuid} />
        <span>{image.name}</span>
      </Space>
    )
  }))
}

interface IPodForm {
  company: string
  data: IProductPod
  onCancel: () => void
  numForm: string
  onClose?: () => void
  type: 'pod' | 'vm'
}

const PodForm = ({ company, data, onCancel, numForm, onClose, type }: IPodForm) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [selectedDc, setSelectedDc] = useState<string>(data.dcs[0])
  const theme = useTheme()

  let options
  if (type === 'pod') {
    options = transformImagesToOptions(data.images)
  } else {
    options = transformImagesToOptions(data.oses)
  }

  const handleFinish = async (values: IProductPod) => {
    try {
      if (type == 'pod') {
        await api.post('/pod/order', {
          ...values,
          product: data.uuid,
          company: company,
          dc: selectedDc
        }).then(() => {
          message.success(t('SUCCESSPOD'))
          onCancel()
          onClose && onClose()
        })
      } else {
        await api.post('/vps/order', {
          ...values,
          product: data.uuid,
          company: company,
          dc: selectedDc
        })
        Modal.success({
          title: t('SUCCESSVM'),
          content: t('VMEMAIL'),
          onOk: () => {
            onCancel()
            if (onClose) onClose()
          }
        })
      }
    } catch (error) {
      message.error(t(type === 'pod' ? 'ERRORCREATEPOD' : 'ERRORCREATEVM'))
    }
  }

  const handleCardClick = (dc: string) => {
    setSelectedDc(dc)
    form.setFieldsValue({ dc: dc })
  }

  return (
    <Form
      name="autofill"
      layout="vertical"
      initialValues={type == 'pod' ? { image: data.images[0].uuid, dc: data.dcs[0] } : { os: data.oses[0].uuid, dc: data.dcs[0] }}
      form={form}
      onFinish={handleFinish}
      id={numForm}
    >
      <Row style={{ width: '100%' }}>
        <Col {...{ span: 24 }}>
          <Form.Item
            rules={[{ required: true, message: t('requiredItem') }]}
            name={type == 'pod' ? "image" : 'os'}
            label="Template"
          >
            <Select
              placeholder={t('CHOOSEANITEM')}
              options={options}
              data-cy="select-input"
            ></Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Typography.Text>{`${t('CHOOSEDC')}`}</Typography.Text>
        </Col>
        {data.dcs.map(dc => (
          <Col key={dc} xs={{ span: 24 }} xl={{ span: 8 }} style={{
            textAlign: 'center',
            padding: '5px'
          }}>
            <Card
              hoverable
              style={{
                backgroundColor: dc === selectedDc ? theme.blue : theme.background,
                color: dc === selectedDc ? "white" : theme.text,
                cursor: 'pointer',
                margin: 'none',
                textTransform: 'uppercase'
              }}
              data-cy="dcs-options"
              variant="borderless"
              onClick={() => handleCardClick(dc)}
            >
              {dc}
            </Card>
          </Col>
        ))}
        <Col span={24}>
          <Typography.Text >{`${t('DESCRIPTION')}:`}</Typography.Text>
          <TextMarkdown text={data.description} />
        </Col>
      </Row>
    </Form>
  )
}

export default PodForm
