import { useState, useEffect, useContext } from 'react'
import { Switch, Tag, Row, Select, Form, Space, Col, Typography, Tooltip } from 'antd'
import { api } from '@helpers/api'
import {
  InitialNamesType,
  DefaultSearchSelectProps,
  SupportComponentsProps,
  TicketSelectProps
} from '@/types/ITicketModalItems'
import { mapItemswithNameandUUIDFilter } from '@helpers/mapItemswithNameandUUIDFilter'
import { getGenericListsandFilter } from '@helpers/getGenericListsandFilter'
import Icon from '@common/Icon'
import { SearchSelectWithForm } from '@common/SearchSelectWithForm'
import SelectForAll from '../SelectForAll'
import RadioForAll from '../RadioForAll'
import { ModalContext } from '@contexts/ModalContext'
import SUsersSelect from '@components/Ticket/SUserSelect'
import { convertKeysToTranslatedOptions } from '@helpers/convertKeysToOptions'
import { useTranslation } from 'react-i18next'
import { humanizeDurationsCompact } from '@helpers/humanizeDurations'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import useSearchSelect from '@hooks/useSearchSelect'
import usePaginationSelect from '@hooks/usePaginationSelect'
import { IService } from '@/types/IService'
import { ITicket, IPriorities } from '@/types/ITicket'
import { useUser } from '@contexts/UserContext'

const { Option } = Select
const { Text } = Typography

type IsStepsFinishedType = {
  isStepsFinished: boolean
}

const StatusTicketSelect = ({ tkid }: { tkid?: string | number }) => {
  const context = useContext(ModalContext)
  const { t } = useTranslation()
  const [options, setOptions] = useState<{ label: string; value: string }[]>()

  const convertedObj = convertKeysToTranslatedOptions(
    TICKETSTATUSESCOLORS,
    t
  ).slice(0, -3)

  useEffect(() => {
    if (tkid && context?.open) {
      api
        .get<IsStepsFinishedType>(`/ticket/${tkid}/isStepsFinished`)
        .then(e => {
          e.data.isStepsFinished
            ? setOptions(convertedObj)
            : setOptions(convertedObj.slice(0, -2))
        })
      return
    }
    setOptions(convertedObj)
  }, [context?.open, tkid])
  return <SelectForAll span={6} name="status" options={options} />
}

const PriorityTicketSelect = ({
  disabled,
  message
}: {
  disabled: boolean
  message?: string
}) => {
  const { t } = useTranslation()
  const options = convertKeysToTranslatedOptions(
    TICKETSPRIORITIESCOLORS,
    t
  ).slice(0, -2)
  return (
    <Tooltip title={disabled ? message : ''} placement="top">
      <SelectForAll
        span={6}
        id="priority"
        name="priority"
        options={options}
        disabled={disabled}
      />
    </Tooltip>
  )
}

export const SupportComponents = ({
  data,
  type,
  form
}: SupportComponentsProps) => {
  const [priorityDisabled, setPriorityDisabled] = useState(false)
  const [priorityMessage, setPriorityMessage] = useState<string | undefined>(
    undefined
  )
  const { user } = useUser()
  const { t } = useTranslation()

  useEffect(() => {
    const disablePriority = () => {
      if (
        data?.service?.priority &&
        user?.level <= 900
      ) {
        const shouldDisablePriority =
          data.service.priority !== 'UNDEFINED' &&
          data.service.priority !== 'UNKNOWN'
        setPriorityDisabled(shouldDisablePriority)
        setPriorityMessage(
          shouldDisablePriority ? t('PRIORITY_NOT_EDITABLE') : undefined
        )
      } else {
        setPriorityDisabled(false)
        setPriorityMessage(undefined)
      }
    }

    disablePriority()
  }, [data?.service?.priority, user?.level, t])

  return (
    <>
      {type !== 'create' ? (
        <>
          <StatusTicketSelect {...{ tkid: data?.tkid }} />
          <SUsersSelect span={5} data={data} />
        </>
      ) : null}
      <PriorityTicketSelect
        disabled={priorityDisabled}
        message={priorityMessage}
      />
      <ServicesSelect
        data={data}
        form={form}
        setPriorityDisabled={setPriorityDisabled}
        userLevel={user?.level}
        setPriorityMessage={setPriorityMessage}
      />
      <DefaultSearchSelect name="sector" span={5} data={data} />
      <DefaultSearchSelect name="company" data={data} span={6} />
      <RadioForAll name="category" required />
    </>
  )
}

const ticketSearchSelectFuncs = () => ({
  sector: async (e?: string) =>
    await getGenericListsandFilter(`sector?search=${e}`),
  company: async (e?: string) =>
    await mapItemswithNameandUUIDFilter(`company?search=${e}`)
})

const initialValueSearchSelect = ({
  name = 'service',
  data
}: {
  name: InitialNamesType
  data?: ITicket
}) => {
  try {
    const initialValue = [
      {
        label: data?.[name].name ? data[name].name : null,
        value: data?.[name].uuid ? data[name].uuid : ''
      }
    ]
    return initialValue
  } catch {
    return undefined
  }
}

export const DefaultSearchSelect = ({
  name,
  data,
  span = 6,
  ...props
}: DefaultSearchSelectProps) => {
  const { t } = useTranslation()
  const func = ticketSearchSelectFuncs()[name]
  const { loading, options, onSearch } = useSearchSelect({
    initialValue: initialValueSearchSelect({ name, data }),
    func
  })

  return (
    <SearchSelectWithForm
      {...props}
      span={span}
      name={name}
      allowClear
      optionLabelProp="label"
      placeholder={t('CHOOSEANITEM')}
      loading={loading}
      onFocus={() => onSearch('')}
      onSearch={onSearch}
      filterOption={false}
      options={options}
      showSearch
    />
  )
}

export const ServicesSelect = ({
  data,
  form,
  span = 12,
  setPriorityDisabled,
  userLevel,
  setPriorityMessage,
  ...props
}: TicketSelectProps & {
  setPriorityDisabled: (disabled: boolean) => void
  userLevel: number | undefined
  setPriorityMessage: (message: string | undefined) => void
}) => {
  const getServices = async (e?: string, page = 1) => {
    return await api
      .get(`ticket/service?search=${e}&page=${page}&perPage=15`)
      .then(e => e.data)
  }
  const { loading, options, onSearch, onPopupScroll } = usePaginationSelect({
    initialValue: data && data?.service !== null ? [data?.service] : undefined,
    func: getServices
  })
  const { t } = useTranslation()

  const handleServiceSelect = (value: string) => {
    const selectedService = options?.find(
      (service: IService) => service.uuid === value
    )
    const fieldsToUpdate: {
      category?: string
      sector?: string
      priority?: IPriorities | null
    } = {}

    if (selectedService?.category?.slug) {
      fieldsToUpdate.category = selectedService?.category?.slug
    }
    if (selectedService?.sector?.slug) {
      fieldsToUpdate.sector = selectedService?.sector?.slug
    }

    const shouldDisable =
      userLevel <= 900 &&
      selectedService?.priority && !['UNDEFINED', 'UNKOWN'].includes(selectedService.priority)

    if (selectedService?.priority) {
      fieldsToUpdate.priority = selectedService?.priority as IPriorities
    }

    setPriorityDisabled(shouldDisable)
    setPriorityMessage(shouldDisable ? t('PRIORITY_NOT_EDITABLE') : undefined)

    form?.setFieldsValue(fieldsToUpdate)
  }

  return (
    <Col xs={{ span: 24 }} xl={{ span: span }}>
      <Form.Item name="service">
        <SearchSelectWithForm
          {...props}
          name="service"
          loading={loading}
          onSearch={onSearch}
          onFocus={async () => onSearch('')}
          onChange={handleServiceSelect}
          onPopupScroll={onPopupScroll}
        >
          {renderOptions(options)}
        </SearchSelectWithForm>
      </Form.Item>
    </Col>
  )
}

const renderOptions = (options: any) => {
  return (
    <>
      {!!options
        ? options.map((e: IService) => (
          <Option value={e.uuid} key={e.uuid} label={e.name}>
            <Row style={{ width: '100%' }} justify="space-between">
              <Text>{e.name}</Text>
              {e.expected_time ? (
                <Tag color="blue-inverse">
                  {humanizeDurationsCompact(e.expected_time)}
                </Tag>
              ) : null}
            </Row>
          </Option>
        ))
        : null}
    </>
  )
}

export const IsPrivateConfirm = ({
  defaultValue = false,
  isFollowup = false,
  service = false
}) => {
  const { t } = useTranslation()
  return (
    <Col span={24}>
      <Space>
        <Form.Item
          name={isFollowup ? 'f_is_private' : 'is_private'}
          valuePropName="checked"
          noStyle
        >
          <Switch
            defaultChecked={defaultValue}
            checkedChildren={<Icon name="fal fa-lock" color="white" />}
            unCheckedChildren={<Icon name="fal fa-users" color="white" />}
          />
        </Form.Item>
        <Typography.Text>
          {(() => {
            if (service) return t('PRIVATESERVICE')
            if (isFollowup) return t('PRIVATEFOLLOWUP')
            return t('PRIVATETICKET')
          })()}
        </Typography.Text>
      </Space>
    </Col>
  )
}
