import { useTranslation } from "react-i18next"
import { Input, Row, Form, Col, Divider, Button, Card } from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import { FormListFieldData } from "antd/lib"

interface InputDeviceAccessProps {
  field: FormListFieldData
  name: string
}

const deviceInputItems = ['key', 'ip', 'port', 'url']

const InputDeviceAccess = ({ field, name }: InputDeviceAccessProps) => {
  const { t } = useTranslation()
  return (
    <Col span={6}>
      <Form.Item {...field} name={[field.name, name]} label={t(name.toUpperCase())}>
        <Input />
      </Form.Item>
    </Col>
  )
}


const EditDeviceAccess = () => {
  const { t } = useTranslation()
  return (
    <Col span={24}>
      <Divider>{t('WAYSTOACCESS')}</Divider>
      <Row style={{ width: '100%' }} gutter={[8, 8]}>
        <Form.List name="access">
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Col key={field.key} xs={{ span: 24 }} xl={{ span: 24 }}>
                  <Card type="inner">
                    <Row gutter={[8, 8]}>
                      {deviceInputItems.map((name) => (
                        <InputDeviceAccess key={name} field={field} name={name} />
                      ))}
                      <Col span={24}>
                        <Button data-cy='delete-access' danger onClick={() => remove(field.name)} type='primary' block>
                          {t('REMOVEITEM')}
                          <MinusCircleOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
              <Button
                data-cy='add-access'
                type="primary"
                onClick={() => add()}
                icon={<PlusOutlined />}
                block
              >
                {t('ADDITEM')}
              </Button>
            </>
          )}
        </Form.List>
      </Row>
    </Col >
  )
}

export default EditDeviceAccess
