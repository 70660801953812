import { api } from "@helpers/api"
import { mapConstantsItemsFilter } from "@helpers/mapConstantsItemsFilter"
import { IDevice } from "@/types/IDevice"
import { Col, Row, Form, Input, Select, Radio, FormProps } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InputWithForm from "@components/common/InputWithForm"
import { SearchSelectWithForm } from "@components/common/SearchSelectWithForm"
import EditDeviceAccess from "../EditDeviceAccess"
import EditDeviceSecrets from "../EditDeviceSecrets"
import EditDeviceHardware from "../EditDeviceHardware"
import useSearchSelect from "@hooks/useSearchSelect"
import { CheckboxOptionType, SelectProps } from "antd/lib"
import { validateNewSecretFormat } from "@helpers/validateNewSecretFormat"
import { useGenericContext } from "@hooks/useGenericContext"
import { DeviceContext } from "@contexts/DeviceContext"

interface DeviceEditSelectProps extends SelectProps {
  url: string
}

const Option = Select.Option
const vminputs = ['cpu', 'mem']
const hostinputs = ['alias']

const handleSecrets = (secrets: IDevice['secrets']) => {
  if (validateNewSecretFormat(secrets) ?? typeof secrets === 'string') {
    return secrets
  }
  if (typeof secrets === 'object')
    return JSON.stringify(secrets, null, 4)

}

const handleAccess = (access: IDevice['access']) => {
  if (!access) return []
  if (typeof access === 'object') {
    const item = Object.keys(access).map(e => ({
      key: e, ...access[e]
    }))
    return item
  }
}

const configInitialValues = (data: IDevice | undefined) => {
  if (data) return {
    ...data,
    dc: data?.dc?.uuid ?? null,
    parent: data?.parent?.uuid ?? null,
    company: data?.company?.uuid,
    os: data?.os?.uuid ?? null,
    secrets: handleSecrets(data?.secrets),
    access: handleAccess(data?.access),
    diskcontrollers: data.diskcontrollers ?? [],
    disks: data.disks ?? [],
  }
}

const DeviceTypeRadio = () => {
  const { t } = useTranslation()
  const [types, setTypes] = useState<DefaultOptionType[] | []>([])
  useEffect(() => {
    api.get('/constants')
      .then(e => {
        setTypes(
          mapConstantsItemsFilter(e, 'DEVICE_TYPES')
            .map(e => ({ ...e, label: t(e.label) }))
            .slice(1, types.length - 1)
        )
      })
  }, [])

  return (
    <Col span={24}>
      <Form.Item name='type' label={t('TYPE')}>
        <Radio.Group options={types as CheckboxOptionType[]} />
      </Form.Item>
    </Col>
  )
}

const DevicePoolSelect = () => {
  const { t } = useTranslation()
  const selectProps = useSearchSelect<DefaultOptionType[], DefaultOptionType[]>({
    func: async () => await api.get('/constants')
      .then(e => {
        return mapConstantsItemsFilter(e, 'DEVICE_BAREMETAL_POOLS')
          .map((e: DefaultOptionType) => ({ ...e, label: t(e.label) }))
      })
  })
  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <Form.Item name="pool" label={t("VIEWOPTIONS")}>
        <Select {...selectProps} />
      </Form.Item>
    </Col>
  )
}
const DeviceEditSelect = <T,>({ url, ...selectProps }: DeviceEditSelectProps) => {

  const { data } = useGenericContext(DeviceContext)
  const { options, ...props } = useSearchSelect<T, T>({
    func: async (search = '') => await api.get(`/${url}&search=${search}`)
      .then(e => e.data.data),
  })

  useEffect(() => {
    props.onSearch(data[selectProps.name]?.name ?? '')
  }, [data])

  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <SearchSelectWithForm {...{ ...props, ...selectProps }} allowClear required>
        {Array.isArray(options) ? options?.map((e: IDevice) => (
          <Option key={e.uuid} value={e.uuid} label={e.name}>
            {e.name}
          </Option>
        )) : null}
      </SearchSelectWithForm >
    </Col>
  )
}


const DeviceForm = (props: FormProps) => {
  const { data } = useGenericContext(DeviceContext)
  const { t } = useTranslation()
  return (
    <Form layout='vertical' initialValues={configInitialValues(data)} {...props}>
      <Row gutter={[8, 8]}>
        <Col xs={{ span: 24 }} xl={{ span: 16 }}>
          <Row gutter={[8, 8]}>
            {data?.type !== 'BAREMETAL' ?
              <DeviceEditSelect url='device?type=BAREMETAL' name='parent' label='host' />
              : <>
                <DeviceEditSelect url='dc?' name='dc' />
                <DevicePoolSelect />
              </>}
            {['hostname', ...data?.type !== 'BAREMETAL' ? vminputs : hostinputs].map(e =>
              <Col key={e} span={8}>
                <InputWithForm name={e} key={e} label={t(e.toUpperCase())} required />
              </Col>
            )}
            <DeviceEditSelect url='os?' name='os' />
            <DeviceEditSelect url='company?' name='company' />
          </Row>
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <Form.Item name='notes' label={t('OBSERVATIONS')} >
                <Input.TextArea />
              </Form.Item>
              <EditDeviceSecrets />
            </Col>
          </Row>
        </Col>
        {data?.type !== 'BAREMETAL' ? <>
          <DeviceTypeRadio />
          <EditDeviceAccess />
        </>
          : <EditDeviceHardware />}

      </Row>
    </Form>
  )
}

export default DeviceForm
