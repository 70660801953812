import { IProduct } from '@/types/IProduct'
import { ColumnType } from 'antd/es/table'
import { Table as AntTable, Popconfirm, Space, Tag, Tooltip } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import Table from '@components/common/Table'
import useTable from '@hooks/useTable'
import { translateColumns } from '@helpers/translateColumns'
import IconButton from '@components/common/IconButton'
import { MessageContext } from '@contexts/MessageContext'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { handleError } from '@helpers/handleError'
import Icon from '@components/common/Icon'
import ProductModal from '../Modal'
import { UseTable } from '@/types/UseTable'
import { IDc } from '@/types/IDc'

const useDeleteProduct = (record: IProduct) => {
  const messageApi = useContext(MessageContext)
  const { t } = useTranslation()
  const onDelete = async () => {
    return api
      .delete(`/product/${record.uuid}`)
      .then(() =>
        messageApi?.success(`${t('PRODUCT')} ${record.name} ${t('DELETED')}`)
      )
      .catch(e => handleError(e, messageApi))
  }
  return onDelete
}

const DeleteProductButton = ({ record }: { record: IProduct }) => {
  const { t } = useTranslation()
  const onDelete = useDeleteProduct(record)

  return (
    <Popconfirm title={t('AREYOUSUREDELETEPRODUCT')} onConfirm={onDelete}>
      <IconButton
        danger
        name="fa-light fa-trash"
        data-cy={`delete-product-${record.uuid}`}
      />
    </Popconfirm>
  )
}

const Actions = ({ record }: { record: IProduct }) => {
  return (
    <Space>
      <DeleteProductButton record={record} />
      <ProductModal
        data-cy={`edit-product-${record.uuid}`}
        data={record}
        type="primary"
        icon={<Icon name="fa-light fa-pencil" color="white" />}
      />
    </Space>
  )
}

const RequiredFlag = ({ required }: { required: boolean }) => {
  const { t } = useTranslation()
  return (
    <Tag color={required ? 'lime-inverse' : 'red-inverse'}>
      {t(String(!!required).toUpperCase())}
    </Tag>
  )
}

export const columns: ColumnType<IProduct>[] = [
  {
    key: 'uuid',
    title: 'ACTIONS',
    dataIndex: 'uuid',
    fixed: 'left',
    width: '100px',
    render: (_, record, index) => <Actions record={record} index={index} />
  },
  {
    key: 'name',
    title: 'NAME',
    dataIndex: 'name'
  }
]

const itemColumns: ColumnType<IProductItem>[] = [
  {
    key: 'item',
    title: 'ITEM',
    dataIndex: 'item',
    render: (value, record) => (<Tooltip title={record.description}>
      <Space>
        {value}
        {record.description ? <QuestionCircleOutlined /> : null}
      </Space>
    </Tooltip>)
  },
  {
    key: 'icon_name',
    title: 'ICONNAME',
    dataIndex: 'icon_name',
    render: (value) => (<Icon name={`fa-solid ${value}`} />)
  },
  {
    key: 'type',
    title: 'TYPE',
    dataIndex: 'type'
  },
  {
    key: 'price',
    title: 'PRICE',
    dataIndex: 'price'
  },
  {
    key: 'default',
    title: 'DEFAULT',
    dataIndex: 'default'
  },
  {
    key: 'min',
    title: 'MIN',
    dataIndex: 'min'
  },
  {
    key: 'max',
    title: 'MAX',
    dataIndex: 'max'
  },
  {
    key: 'override_name',
    title: 'OVERRIDENAME',
    dataIndex: 'override_name'
  },
  {
    key: 'required',
    title: 'REQUIRED',
    dataIndex: 'required',
    render: (value, record) => <RequiredFlag required={value} />
  },
  {
    key: 'POSTFIX',
    title: 'POSTFIX',
    dataIndex: 'postfix'
  },
  {
    key: 'PREFIX',
    title: 'PREFIX',
    dataIndex: 'prefix'
  },
  {
    key: 'STEPS',
    title: 'STEPS',
    dataIndex: 'step'
  }
]

export const useProductstTab = () => {
  const { t } = useTranslation()
  const [dcs, setDcs] = useState<IDc[]>()
  useEffect(() => {
    api.get('dc?perPage=999').then(e => setDcs(e.data.data))
  }, [])


  const tabs = []

  tabs.push({
    key: 0,
    label: t('ALL'),
    children: <ProductTable />
  })

  if (dcs) {
    dcs.forEach((dc, index) => {
      tabs.push({
        key: index + 1,
        label: t(dc.shortname),
        children: <ProductTable dcuuid={dc.uuid} />
      })
    })
  }

  return tabs
}

const ProductTable = ({ dcuuid }: { dcuuid?: string }) => {
  const query = dcuuid ? `/product?dc=${dcuuid}` : `/product`

  const tableProps = useTable(query)

  return <ProductTableWithoutQuery {...tableProps} />
}

export const ProductTableWithoutQuery = (props: UseTable<any, any>) => {
  return (
    <Table
      {...props}
      columns={columns}
      expandable={{
        expandedRowRender: (record, key) => (
          <ProductItemTable record={record} key={key} />
        ),
        defaultExpandedRowKeys: ['0']
      }}
    />
  )
}

export const ProductItemTable = ({ record }: { record: any }) => {
  const { t } = useTranslation()
  return (
    <AntTable
      columns={translateColumns(itemColumns, t)}
      dataSource={record.items}
      pagination={false}
    />
  )
}
