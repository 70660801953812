import { IProductPod } from '@/types/IProduct'
import { CustomButton } from '@components/Ticket/Modal/index.tsx'
import Icon from '@components/common/Icon'
import Modal from '@components/common/Modal/index.tsx'
import ModalFooter from '@components/common/ModalFooter/index.tsx'
import { ModalContext } from '@contexts/ModalContext'
import { useUser } from '@contexts/UserContext'
import useModal from '@hooks/useModal'
import { ButtonProps } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PodForm from '../Form'
import {
  KubernetesOutlined
} from '@ant-design/icons'

interface ModalPodProps extends Omit<ButtonProps, 'type'> {
  data: IProductPod
  onClose?: () => void
  type: 'pod' | 'VM'
}

export const value_to_currency = (value: number, language: string, currency: string) => {
  return value.toLocaleString(language, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  })
}

const ModalPod = ({ data, onClose, type, ...props }: ModalPodProps) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { open, onCancel, onOpen } = useModal()

  const numForm = (
    crypto.getRandomValues(new Uint32Array(1))[0] % 10000
  ).toString()
  const memoOpen = useMemo(
    () => ({ open, onClose, onOpen }),
    [open, onClose, onOpen]
  )

  return (
    <ModalContext.Provider value={memoOpen}>
      <Modal
        title={type == 'pod' ? `${t('ADDPOD')} - ${value_to_currency(data.price, 'pt-BR', 'BRl')} ${t('PERHOUR')}` :
          `${t('ADDVM')} - ${value_to_currency(data.price, 'pt-BR', 'BRl')} ${t('PERMONTH')}`
        }
        open={open}
        footer={
          <ModalFooter onClose={onCancel} action={'create'} form={numForm} />
        }
        onCancel={onCancel}
        centered
        width="30%"
      >
        <PodForm
          company={user.companies[0].uuid}
          data={data}
          onCancel={onCancel}
          numForm={numForm}
          onClose={onClose}
          type={type}
        />
      </Modal>
      <CustomButton
        disabled={!data.available}
        data-cy={`modal-${type}`}
        onClick={onOpen}
        block
        type="primary"
        style={{ width: 200, marginBottom: 5 }}
        icon={
          type === 'pod' ? (
            <KubernetesOutlined style={{
              fontSize: 24
            }} />
          ) : (
            <Icon name="far fa-desktop" color={data.available ? 'white' : 'gray'} />
          )
        }
        {...props}
      />
    </ModalContext.Provider>
  )
}

export default ModalPod
