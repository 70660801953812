import { Bar } from '@ant-design/plots';
import myData from './teste.json';

const MeetingsGraph = () => {

  const data = myData.map(item => ({
    meetings: item.meetings,
    userName: item.user.name,
    age: item.user.age // Assuming age is a property you want to use for colorField
  }));

  const config = {
    data: data,
    xField: 'meetings', // Number of meetings on the x-axis
    yField: 'userName', // User's name on the y-axis
    colorField: 'age', // Assuming you want to color by age
    stack: true,
    sort: {
      reverse: true,
      by: 'y'
    },
    axis: {
      y: { labelFormatter: '~s' },
      x: {
        labelSpacing: 4,
      }
    }
  };

  return (
    <Bar {...config} />
  );
};

export default MeetingsGraph;
