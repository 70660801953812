import { useEffect, useMemo, useState } from 'react'
import Title from '@common/Title'
import Filter from '@components/Device/GroupFilter'
import SubTitle from '@common/SubTitle'
import ModalTicket from '@components/Ticket/Modal'
import { useUser } from '@contexts/UserContext'
import { columns } from '@components/Ticket/Table'
import { useTranslation } from 'react-i18next'
import { IDashboardTableCard } from '@/types/IDashboard'
import Alert from '@common/Alert'
import TicketList from '@components/Ticket/TicketList'
import useWindowResize from '@hooks/useWindowResize'
import useTable from '@hooks/useTable'
import Card from '@components/common/Card'
import useFetch from '@hooks/useFetch'
import { IProductPod, IProductVm } from '@/types/IProduct'
import useSocket from '@hooks/useSocket'
import {
  ButtonProps,
  Col,
  Collapse,
  FloatButton,
  Row,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import { useGenericContext } from '@hooks/useGenericContext'
import Table from '@components/common/Table'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import Icon from '@components/common/Icon'
import { DisplayContext } from '@contexts/DisplayContext'
import ClearFilterButton from '@components/common/ClearFilterButton'
import Summary from '@components/Dashboard/Summary'
import { Text } from '@components/common/Text'
import DeviceTableList from '@components/Device/DeviceTableList'
import { api } from '@helpers/api'
import OnboardingSection from '@components/OnBoard/OnboardingSection'
import { useTheme } from 'styled-components'
import BalanceDrawer from '@components/Pod/Drawer'
import { ActionsItems } from '@components/Pod/Actions'
import ModalBalance from '@components/Balance/Modal'
import CardsPod from '@components/Pod/Card'
import RenderPage from '@components/common/RenderPage'
import { value_to_currency } from '@components/Pod/Modal'

interface MarketPlaceProps {
  podData: IProductPod;
  vmData: IProductVm;
}

interface PodButtonProps extends ButtonProps {
  floatButton?: boolean
  productType: 'pod' | "VM"
  data: IProductPod | IProductVm
}

const PodButton = ({ data, floatButton, productType, ...props }: PodButtonProps) => {
  const { balance } = useUser()
  const crypto = window.crypto
  const array = new Uint32Array(1)
  const numForm = crypto.getRandomValues(array)[0].toString()

  return (
    <BalanceDrawer data={data} balance={balance} numForm={numForm} type={productType}>
      <ActionsItems data={balance} floatButton={floatButton} {...props} productType={productType} />
    </BalanceDrawer>
  )
}

const FloatCreateTicket = ({ podData, vmData }: MarketPlaceProps) => {
  const { balance } = useUser()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <FloatButton.Group
      trigger="click"
      open={open}
      onClick={() => setOpen(!open)}
      type="primary"
      style={{ insetInlineEnd: 24 }}
      icon={<Icon name="far fa-bars" color="white" size={'15'} />}
    >
      {balance.enabled && (
        <>
          <PodButton key="pod-button" floatButton={true} type="primary" data={podData} productType='pod' />
          <PodButton key="pod-button" floatButton={true} type="primary" data={vmData} productType='VM' />
        </>
      )}
      {(!balance.enabled || balance.hasDevices) && (
        <ModalTicket
          key="modal-ticket"
          action="create"
          tooltip={t('CREATETICKET')}
          float={true}
          type="primary"
          style={{ right: 50, zIndex: 1 }}
          icon={<Icon name="fal fa-tags" color="white" size={'15'} />}
        />
      )}
    </FloatButton.Group>
  )
}

const defineColor = (name: string) => {
  const keys = Object.keys(TICKETSTATUSESCOLORS)
  const definedKey =
    keys.find(key => key.includes(name.toUpperCase().split(' ')[0])) || 'NEW'
  return TICKETSTATUSESCOLORS[definedKey].color
}

const TableCard = ({ url, name }: IDashboardTableCard) => {
  const { list } = useGenericContext(DisplayContext)
  const props = useTable(url)
  const { width } = useWindowResize()
  if (width < 768) {
    return (
      <>
        <SubTitle>{name}</SubTitle>
        <Card {...props} columns={columns} />
      </>
    )
  }
  return (
    <>
      <SubTitle>
        {name + ' '}{' '}
        {props?.data?.meta?.total ? (
          <Tag
            color={defineColor(name) || 'blue-inverse'}
            style={{ borderRadius: '10px' }}
          >
            {props?.data?.meta?.total}
          </Tag>
        ) : null}
      </SubTitle>
      {list === 'list' ? (
        <TicketList {...props} />
      ) : (
        <Table
          {...props}
          columns={columns}
          handleTableChange={props.handleTableChange}
        />
      )}
    </>
  )
}

const ShowTables = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const uri = '/ticket?status='
  return (
    <>
      <TableCard url={`${uri}NEW`} name={t('NEWTICKETS')} />
      <TableCard url={`${uri}PENDING`} name={t('PENDINGTICKETS')} />
      {user.level !== 1 ? (
        <TableCard
          url={`${uri}PROCESSING_ATTRIBUTED&assignedTo=${user.uuid}`}
          name={t('ATRIBUTEDTICKETS')}
        />
      ) : null}
      <TableCard
        url={`${uri}PROCESSING_ATTRIBUTED,PROCESSING_PLANNED`}
        name={t('PROCESSINGTICKETS')}
      />
    </>
  )
}

const useSwicthDisplayButton = () => {
  const { setList, list } = useGenericContext(DisplayContext)
  const changeList = () => {
    setList(prevList => {
      const newDisplay = prevList === 'list' ? 'table' : 'list'
      localStorage.setItem('dashboardlist', newDisplay)
      return newDisplay
    })
  }
  return { list, changeList }
}

const SwitchDisplayButton = () => {
  const { list, changeList } = useSwicthDisplayButton()
  const { t } = useTranslation()
  return (
    <Col xl={{ span: 24 }} xs={{ span: 24 }}>
      <Row justify="end" gutter={[16, 16]}>
        <Space>
          <Tooltip title={t('CLICKTOCHANGETHEVISUALIZATIONMODEL')}>
            <Text>{list === 'table' ? t('VIEWONTABLE') : t('VIEWONLIST')}</Text>
          </Tooltip>
          <Switch
            data-cy="switch-table-list"
            checkedChildren={<Icon name="fal fa-table-list" color="white" />}
            unCheckedChildren={<Icon name="fal fa-list" color="white" />}
            onClick={changeList}
          />
        </Space>
      </Row>
    </Col>
  )
}


const DashboardTitle = ({ podData, vmData }: MarketPlaceProps) => {
  const { user, balance } = useUser()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Title name="Dashboard">
      <Row
        style={{ width: '100%', paddingBottom: 10 }}
        justify="space-between"
        align="middle"
      >
        <Col xs={24} xl={8}>
          <Typography.Title data-cy="title">Dashboard</Typography.Title>
          <Row align="middle" style={{ gap: '10px' }}>
            {user.level > 1 ? (
              <ClearFilterButton uri={'/'} item={'dashboard'} shape="round" />
            ) : null}
          </Row>
        </Col>
        {balance.enabled ? (
          <Col
            xs={24}
            xl={{
              span: 13,
              offset: 3
            }}
          >
            <Row align="middle" justify={'end'} gutter={[8, 8]}>
              <Col xs={24} xl={6}>
                <Typography.Title data-cy="balance" style={{ paddingTop: 10 }}>
                  {`${t('BALANCE')}: ${value_to_currency(balance.balance, 'pt-BR', 'BRL')}`}
                </Typography.Title>
              </Col>
              <Col xs={24} xl={6}>
                <ModalBalance
                  block
                  type="primary"
                  icon={<Icon name="far fa-dollar-sign" color="white" size={18} />}
                  data-cy={`balanceModal`}
                  style={{ paddingInline: 47, background: theme.green }}
                >
                  {t('ADDBALANCE')}
                </ModalBalance>
              </Col>
              <Col xs={24} xl={6}>
                <PodButton data={podData} productType='pod' style={{ paddingInline: 47 }} />
              </Col>
              <Col xs={24} xl={6}>
                <PodButton data={vmData} productType='VM' style={{ paddingInline: 47 }} />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </Title>
  )
}

const DeviceSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <SubTitle style={{ marginTop: '8px' }}>{t('MYDEVICES')}</SubTitle>
      <DeviceTableList />
    </>
  )
}

const UserItems = () => (
  <Col span={24}>
    <Alert style={{ marginBottom: '2%', marginTop: '1%' }} />
    <Filter />
  </Col>
)

const useOnboardData = () => {
  const [onboardStatus, setOnboardStatus] = useState('')
  const [hasFilledForm, setHasFilledForm] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    if (user.level === 1) {
      api.get('/onboard/customer').then(onboardResponse => {
        if (!onboardResponse.data.length) {
          setHasFilledForm(true)
        }
        api.get('/company').then(companyResponse => {
          const company = companyResponse?.data?.data[0]

          if (company) {
            setOnboardStatus(company.onboard_status)
          }
        })
      })
    }
  }, [user.level])

  return { onboardStatus, hasFilledForm }
}

const Dashboard = () => {
  const { t } = useTranslation()
  const { width } = useWindowResize()
  const { onboardStatus, hasFilledForm } = useOnboardData()
  const [list, setList] = useState(() => {
    const current = localStorage.getItem('dashboardlist')
    return current ?? 'list'
  })
  const contextValue = useMemo(() => ({ list, setList }), [list, setList]);
  const { user, balance, setBalance } = useUser()

  const shouldFetch = balance.enabled

  const { data, loading, error } = useFetch<IProductPod, undefined>({
    func: () => shouldFetch ? api.get('pod/product') : Promise.resolve([]),
    initialValue: [],
    deps: [shouldFetch]
  })

  const { data: dataVm, loading: loadingVm, error: errorVm } = useFetch<IProductVm, undefined>({
    func: () => shouldFetch ? api.get('/vps/product') : Promise.resolve([]),
    initialValue: [],
    deps: [shouldFetch]
  })

  const fetchData = async () => {
    await api.get('/balance').then(res => {
      setBalance(res.data)
    })
  }

  useSocket(fetchData, 'DeviceUpdated')

  const items = balance ? getCollapseItems({ t, balance, data, loading, error, dataVm, loadingVm, errorVm }) : []

  return (
    <DisplayContext.Provider value={contextValue}>
      {renderOnboardingSection(hasFilledForm, user, onboardStatus)}
      <DashboardTitle podData={data} vmData={dataVm} />
      <FloatCreateTicket vmData={dataVm} podData={data} />
      {renderCollapse(balance, items)}
      {renderContent(balance, user, width)}
    </DisplayContext.Provider>
  )
}

const getCollapseItems = ({ t, balance, data, loading, error, dataVm, loadingVm, errorVm }) => [
  {
    key: '1',
    label: t('DEVICESUMMARY'),
    children: <Summary />
  },
  {
    key: '2',
    label: t('BUYPODS'),
    children: <>
      {(balance.enabled && balance.hasDevices) || !balance.enabled ?
        null
        : (
          <div style={{ marginTop: 30 }}>
            <RenderPage {...{ data, loading, error }}>
              <CardsPod data={data.data} type='pod' />
            </RenderPage>
          </div>
        )}
    </>
  },
  {
    key: '3',
    label: t('BUYVMS'),
    children: <>
      {(balance.enabled && balance.hasDevices) || !balance.enabled ?
        null
        : (
          <div style={{ marginTop: 30 }}>
            <RenderPage {...{ data: dataVm, loading: loadingVm, error: errorVm }}>
              <CardsPod data={dataVm.data} type='VM' />
            </RenderPage>
          </div>
        )}
    </>
  }
]

const renderOnboardingSection = (hasFilledForm, user, onboardStatus) => (
  hasFilledForm &&
    user.level === 1 &&
    onboardStatus === 'WAITING_FIRST_DEVICE_ACCESS_MAIL' ? (
    <OnboardingSection />
  ) : null
)

const renderCollapse = (balance, items) => (
  balance.enabled ? (
    <Collapse
      items={balance.hasDevices ? items.slice(0, 1) : items}
      defaultActiveKey={balance.hasDevices ? ['1'] : ['3']}
    />
  ) : (
    <Collapse items={items.slice(0, 1)} defaultActiveKey={['1']} />
  )
)

const renderContent = (balance, user, width) => (
  (balance.enabled && balance.hasDevices) || !balance.enabled ? (
    <>
      <Row
        gutter={[8, 8]}
        style={{
          marginTop: '20px'
        }}
      >
        {user.level === 1 ? <UserItems /> : null}
        {width > 768 ? <SwitchDisplayButton /> : null}
      </Row>
      {user.level === 1 ? <DeviceSection /> : null}
      <ShowTables />
    </>
  ) : null
)

export default Dashboard
