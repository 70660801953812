import { DeviceHardwareContextType, ICdp } from '@/types/IDeviceHardware'
import { DeviceHardwareContext } from '@contexts/DeviceHardwareContext'
import { translateColumns } from '@helpers/translateColumns'
import { useGenericContext } from '@hooks/useGenericContext'
import { Alert, Col, Divider } from 'antd'
import { Table } from 'antd/lib'
import { ColumnsType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
const useDeviceHardwareContext = () =>
  useGenericContext<DeviceHardwareContextType>(DeviceHardwareContext)

const columns: ColumnsType<ICdp> = [
  {
    title: 'PNIC',
    dataIndex: 'pnic',
    key: 'pnic'
  },
  {
    title: 'SWINFO',
    dataIndex: 'swinfo',
    key: 'swinfo',
    render: swinfo => {
      return swinfo ? (
        <ul>
          {Object.entries(swinfo).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {String(value)}
            </li>
          ))}
        </ul>
      ) : null
    }
  },
]

const CdpHardware = () => {
  const { t } = useTranslation()
  const { data } = useDeviceHardwareContext()

  return (
    <Col span={24}>
      <Divider>{t('CDP')}</Divider>
      {data.cdp === null ?
        <Alert
          data-cy="spec-alert"
          showIcon
          message={t('NO_REGISTRY')}
          style={{ width: '100%' }}
          type="warning"
        /> :
        <Table
          data-cy="cdp"
          rowKey="id"
          dataSource={data.cdp}
          columns={translateColumns(columns, t)}
        />
      }
    </Col>
  )
}

export default CdpHardware

