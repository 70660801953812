import { Dispatch, SetStateAction } from 'react'
import type { IOrder } from '@/types/IOrder'
import { api } from './api'
import { removeNullValues } from './removeNullValues'
import { formatOrderItem } from '@components/Order/Form'

export const calculateOrderPrice = (
  data: IOrder | undefined,
  setTotalPrice: Dispatch<SetStateAction<number>>
): void => {
  if (data?.items?.[0] !== undefined) {
    const formattedValues = {
      ...removeNullValues(data),
      items: data.items.map(item => ({
        product: { uuid: item.product.uuid, device: item.product?.device },
        order_request: removeNullValues(
          item.order_request?.items.map(item => formatOrderItem(item))
        )
      }))
    }

    api.post(`/order/calculate`, formattedValues).then(res => {
      setTotalPrice(res.data.price_total)
    })
  } else {
    setTotalPrice(0)
  }
}
