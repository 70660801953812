
import InputWithForm from '@components/common/InputWithForm'
import { Text } from '@components/common/Text'
import { DeviceContext } from '@contexts/DeviceContext'
import { useDeviceSecrets } from '@hooks/useDeviceSecrets'
import { useGenericContext } from '@hooks/useGenericContext'
import { Button, Col, Row, Form, Input, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import { validateNewSecretFormat } from '@helpers/validateNewSecretFormat'
import { FormListFieldData } from 'antd/lib'

const CheckSecrets = <T,>({ check }: { check: (values: T) => void }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  return (
    <Form onFinish={check} form={form}>
      <Row style={{ width: '100%' }} gutter={[8, 8]}>
        <Col span={24}>
          <Text>{t('SECRETS')}</Text>
        </Col>
        <Col span={18}>
          <InputWithForm name='masterpwd' />
        </Col>
        <Col span={6}>
          <Button
            data-cy='confirm-secrets'
            type='primary'
            onClick={form.submit}
            block
          >
            {t('CONFIRM')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

interface SecretInputItemProps {
  field: FormListFieldData
  name: string
}

const inputItems = ['key', 'value']

const SecretInputItem = ({ field, name }: SecretInputItemProps) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      {...field}
      name={[field.name, name]}
      rules={[{ required: true, message: t('requiredItem') }]}
    >
      <Input placeholder={t(name)} />
    </Form.Item>
  )
}

const DeviceSecretsInput = ({ secret }) => {
  const { data } = useGenericContext(DeviceContext)
  const [isValid, setIsValid] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    setIsValid(validateNewSecretFormat(data?.secrets))
  }, [data])

  return (
    <>
      {isValid ?
        <>
          <Form.List name={["secrets", "support"]} initialValue={secret?.support}>
            {(fields, { add, remove }) => (
              <div>
                <h3>Support</h3>
                {fields.map(field => (
                  <Space key={field.key} align="baseline" style={{ display: 'flex', marginBottom: 8 }}>
                    {inputItems.map((name) => (
                      <SecretInputItem key={name} field={field} name={name} />
                    ))}
                    <Button
                      id='delete-support'
                      type='primary'
                      danger
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Button
                  data-cy='add-support'
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  block
                >
                  {t('ADDSUPPORTKEYVALUEPAIR')}
                </Button>
              </div>
            )}
          </Form.List>
          <Form.List name={["secrets", "manager"]} initialValue={secret?.manager}>
            {(fields, { add, remove }) => (
              <div>
                <h3>Manager</h3>
                {fields.map(field => (
                  <Space key={field.key} align="baseline" style={{ display: 'flex', marginBottom: 8 }}>
                    {inputItems.map((name) => (
                      <SecretInputItem key={name} field={field} name={name} />
                    ))}
                    <Button
                      id='delete-manager'
                      type='primary'
                      danger
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Button
                  data-cy='add-manager'
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  block
                >
                  {t('ADDMANAGERKEYVALUEPAIR')}
                </Button>
              </div>
            )}
          </Form.List>
        </> :
        <Form.Item name='secrets' label={t('SECRETS')} >
          < Input.TextArea />
        </Form.Item>
      }
    </>
  )
}

const EditDeviceSecrets = () => {
  const context = useGenericContext(DeviceContext)
  const { data, hasMaster, checkPassword, error } = useDeviceSecrets(context.data.uuid)
  return (
    <Col span={24}>
      {!data && !hasMaster ?
        <>
          <CheckSecrets check={checkPassword} />
          {error?.response?.data?.hasOwnProperty('message') ? (
            <Text type="danger">{error?.response?.data.message as any}</Text>
          ) : null}
        </> :
        <DeviceSecretsInput secret={data} />
      }
    </Col>
  )
}
export default EditDeviceSecrets
