import { IBalance } from '@/types/IBalance'
import {
  KubernetesOutlined
} from '@ant-design/icons'
import { CustomButton } from '@components/Ticket/Modal'
import Icon from '@components/common/Icon'
import { BalanceContext } from '@contexts/BalanceContext'
import { useGenericContext } from '@hooks/useGenericContext'

interface ActionsItemsProps {
  data: IBalance
  floatButton?: boolean
  productType: 'pod' | "VM"
}

export const ActionsItems = ({
  data,
  floatButton,
  productType,
  ...props
}: ActionsItemsProps) => {
  const { openDrawer } = useGenericContext(BalanceContext)

  return (
    <CustomButton
      {...props}
      block
      style={{
        justifyContent: "center",
      }}
      icon={
        productType === 'pod' ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <KubernetesOutlined style={{ fontSize: '26px' }} />
          </div>

        ) : (
          <Icon name="far fa-desktop" color='white' />
        )
      }
      type="primary"
      onClick={() => openDrawer(data)}
      float={floatButton}
      data-cy={`create-${productType}`}
    >
      {`Deploy ${productType}`}
    </CustomButton>
  )
}

