import { Welcome as SWelcome, Image as SImage } from './style'
const Welcome = () => {
  return (
    <SWelcome>
      <SImage src='/assets/loading.gif' />
    </SWelcome>
  )
}

export default Welcome
