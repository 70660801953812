import Title from '@components/common/Title'
import RankingCards from '@components/Ranking/Cards'
import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

function Ranking() {
  const { t } = useTranslation()
  return (
    <>
      <Row
        gutter={[10, 5]}
        style={{ marginBottom: '8px' }}
        justify="space-between"
      >
        <Title name={t('Ranking')} />
      </Row>
      <RankingCards />
    </>
  )
}

export default Ranking
