import { useEffect, PropsWithChildren, useState, useContext } from 'react'
import { ModalContext } from '@contexts/ModalContext'
import { api } from '@helpers/api'
import Feedback from '@components/User/Feedback'
import useModal from '@hooks/useModal'
import { UserContext, useUser } from '@contexts/UserContext'
import { useNavigate } from 'react-router-dom'

const FeedbackContext = ({ children }: PropsWithChildren) => {
  const [nps, setNps] = useState([])
  const context = useContext(UserContext)
  const { open, onOpen, onCancel } = useModal()
  const navigate = useNavigate()
  const { user, balance } = useUser()

  useEffect(() => {
    if (localStorage.getItem('POSTPONED') !== 'true') {
      user.level === 1 &&
        api.get('/onboard/customer').then(response => {
          if (response.data.length) {
            const company = response.data[0]
            navigate(`/onboard/customer/${company.uuid}`, {
              state: response.data
            })
          }
        })
    }

    setTimeout(() => {
      if (context?.islogged)
        api.get('/nps').then(e => {
          if (e.data.data.length) {
            onOpen()
            setNps(e.data.data)
          }
        })
    }, 200)
  }, [balance.enabled, context?.islogged, onOpen])

  return (
    <ModalContext.Provider value={{ open, onOpen, onCancel }}>
      <Feedback {...{ open, onOpen, onCancel, nps }} data-cy="nps-feedback" />
      {children}
    </ModalContext.Provider>
  )
}

export default FeedbackContext
