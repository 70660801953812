import { Bar } from '@ant-design/plots';
import myData from './teste.json';
import { useEffect, useRef } from 'react';

const CallsGraph = () => {

  let data = myData.map(item => ({
    not_answered: item.not_answered,
    answered: item.answered,
    user: item.user.name,
    avatarUrl: `https://painel.opendatacenter.com.br/assets/img/avatars/${item.user.email.split('@opendatacenter.com.br')[0]}.png`
  }));

  data.sort(
    (a, b) => b.answered + b.not_answered - (a.answered + a.not_answered)
  );

  const transformData = data =>
    data.flatMap(item => {
      const total = item.answered + item.not_answered;
      return [
        {
          user: item.user,
          avatarUrl: item.avatarUrl,
          type: 'answered',
          value: item.answered
        },
        {
          user: item.user,
          avatarUrl: item.avatarUrl,
          type: 'not_answered',
          value: item.not_answered
        },
        {
          user: item.user,
          avatarUrl: item.avatarUrl,
          type: 'total',
          value: 0,
          total: total
        }
      ]
    });

  const transformedData = transformData(data);
  const uniqueUsers = [...new Set(transformedData.map(item => item.user))];

  const chartRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && containerRef.current) {
      const chartContainer = containerRef.current;

      const barHeight = 34;

      chartContainer.querySelectorAll('.avatar').forEach(avatar => avatar.remove());

      uniqueUsers.forEach((user, index) => {
        const avatarUrl = transformedData.find(d => d.user === user)?.avatarUrl;
        const avatar = document.createElement('img');
        avatar.src = avatarUrl;
        avatar.style.width = '26px';
        avatar.style.height = '26px';
        avatar.style.position = 'absolute';
        avatar.style.left = '120px';
        avatar.style.top = `${index * barHeight + 2}px`
        avatar.style.borderRadius = '50%';
        avatar.classList.add('avatar');
        chartContainer.appendChild(avatar);
      });
    }
  }, [transformedData, uniqueUsers]);

  const config = {
    data: transformedData,
    xField: 'value',
    yField: 'user',
    seriesField: 'type',
    isStack: true,
    legend: false,
    color: ['#1B62F2', '#E86452'],
    label: {
      content: (item) => {
        if (item.type === 'total') {
          return item.total
        }
        return item.value
      },
      position: (item) => {
        return item.type === 'total' ? 'right' : 'middle';
      },
      style: { fill: '#fff' }
    },
    yAxis: {
      label: { style: { fill: '#fff' } },
      line: { style: { stroke: '#ffffff' } },
    },
    theme: 'classicDark',
    meta: {
      user: { alias: 'User' },
      value: { alias: 'Number of Calls' }
    },
    appendPadding: [0, 120, 0, 0],
    columnWidth: 35,
    maxColumnWidth: 35,
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#000',
        position: 'relative'
      }}
      ref={containerRef}
    >
      <Bar {...config} ref={chartRef} />
    </div>
  );
};

export default CallsGraph;
