import { Row, Card, Tag, Space, Typography, Col, Flex, Tooltip } from 'antd'
import { useTheme } from 'styled-components'
import { IProductPod } from '@/types/IProduct'
import TextMarkdown from '@components/common/TextMarkdown'
import ModalBalance from '@components/Balance/Modal'
import Icon from '@components/common/Icon'
import { useTranslation } from 'react-i18next'
import ModalPod, { value_to_currency } from '../Modal'

interface CardsPodProps {
  data: IProductPod[]
  onClose?: () => void
  type: 'pod' | 'VM'
}
interface CardProductsPod {
  item: IProductPod
  onClose?: () => void
  type: 'pod' | 'VM'
}
const CardsPod = ({ data, onClose, type }: CardsPodProps) => {
  return (
    <Flex justify="space-around" wrap gap="small">
      {data?.map((item: IProductPod) => (
        <CardProductsPod onClose={onClose} key={item.uuid} item={item} type={type} />
      ))}
    </Flex>
  )
}

const CardProductsPod = ({ item, onClose, type }: CardProductsPod) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const totalPrice = (item.price * item.discounted) / 100 + item.price
  return (
    <Card
      title={
        <Flex justify="space-around" wrap align="center">
          <Col>
            <Row style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1
            }}>
              <Typography.Title level={5}>{item.title}</Typography.Title>
            </Row>
            <Row style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1
            }}>
              {!item.available ? (
                <Tooltip title={t('NOTAVAILABLE')}>
                  <ModalPod onClose={onClose} data={item} type={type}>
                    {type === 'pod' ? t('ADDPOD') : t('ADDVM')}
                  </ModalPod>
                </Tooltip>
              ) : (
                <>
                  {item.canBuy ? (
                    <ModalPod onClose={onClose} data={item} type={type}>
                      {type === 'pod' ? t('ADDPOD') : t('ADDVM')}
                    </ModalPod>
                  ) : (
                    <ModalBalance
                      block
                      type="primary"
                      icon={<Icon name="far fa-dollar-sign" color="white" />}
                      data-cy="drawerPod"
                      style={{
                        width: 200,
                        marginBottom: 5,
                        background: theme.green
                      }}
                    >
                      {t('ADDBALANCE')}
                    </ModalBalance>
                  )}
                </>
              )}
            </Row>
          </Col>
        </Flex>
      }
      style={{ width: 500 }}
    >
      <Row>
        <Col span={12}>
          <TextMarkdown text={item.description} />
        </Col>
        <Col span={12}>
          <Flex
            vertical
            style={{
              width: '100%',
              height: '100%'
            }}
            justify="flex-end"
            align="flex-end"
          >
            {item.discounted ? (
              <Space>
                <Tag
                  color={theme.green}
                  bordered={false}
                >{`${item.discounted}% OFF`}</Tag>
                <Typography.Text
                  delete
                  style={{ fontSize: 17, color: theme.gray }}
                >
                  {value_to_currency(totalPrice, 'pt-BR', 'BRL')} /{type === 'pod' ? t('PERHOUR') : t('PERMONTH')}
                </Typography.Text>
              </Space>
            ) : null}
            <Typography.Text style={{ fontSize: 22, color: theme.green }}>
              {value_to_currency(item.price, 'pt-BR', 'BRL')} /{type === 'pod' ? t('PERHOUR') : t('PERMONTH')}
            </Typography.Text>
          </Flex>
        </Col>
      </Row>
    </Card>
  )
}

export default CardsPod
