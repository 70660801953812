import { DeviceHardwareContextType, IVlan } from '@/types/IDeviceHardware'
import { DeviceHardwareContext } from '@contexts/DeviceHardwareContext'
import { translateColumns } from '@helpers/translateColumns'
import { useGenericContext } from '@hooks/useGenericContext'
import { Alert, Col, Divider } from 'antd'
import { Table } from 'antd/lib'
import { ColumnsType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
const useDeviceHardwareContext = () =>
  useGenericContext<DeviceHardwareContextType>(DeviceHardwareContext)

const columns: ColumnsType<IVlan> = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'PORTS',
    dataIndex: 'ports',
    key: 'ports',
    render: ports => {
      return ports ? (
        <ul>
          {ports.map((port: { mac: string; type: string }) => (
            <li key={port.mac}>
              <strong>MAC:</strong> {port.mac} - <strong>Type:</strong> {port.type}
            </li>
          ))}
        </ul>
      ) : null
    }
  },
]

const VlansHardware = () => {
  const { t } = useTranslation()
  const { data } = useDeviceHardwareContext()

  return (
    <Col span={24}>
      <Divider>{t('Vlan')}</Divider>
      {data.cdp === null ?
        <Alert
          data-cy="spec-alert"
          showIcon
          message={t('NO_REGISTRY')}
          style={{ width: '100%' }}
          type="warning"
        /> :
        <Table
          data-cy="vlan"
          rowKey="id"
          dataSource={data.vlans}
          columns={translateColumns(columns, t)}
        />
      }
    </Col>
  )
}

export default VlansHardware
