import { useTranslation } from 'react-i18next'
import { TicketContext } from '@contexts/TicketContext'
import { ticketColumn } from '@components/Ticket/Descriptions'
import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Row, Col, Divider } from 'antd'
import { api } from '@helpers/api'
import type { IRequest } from '../../types/ICollection'
import { ITicket, TicketContextType } from '@/types/ITicket'
import Title from '@common/Title'
import { useGenericContext } from '@hooks/useGenericContext'
import TicketHead from '@components/Ticket/Head'
import TicketChecks from '@components/Ticket/Checks'
import Followup from '@components/Ticket/Followup'
import Descriptions from '@components/common/Descriptions'
import RenderPage from '@common/RenderPage'
import useSocket from '@hooks/useSocket'
import UpDownButton from '@common/UpDownButton'
import { useUser } from '@contexts/UserContext'
import { AxiosError } from 'axios'
import FollowupProvider, { FollowupContext } from '@contexts/FollowupContext'
import { ISUser } from '@/types/IUser'

export const queryFetch = async (url?: string) => {
  return await api.get(`${url}`).then((response: IRequest<any>) => response)
}


const TicketContainer = ({ byData }: { byData: boolean | undefined }) => {
  const { t } = useTranslation()
  const { data, setData } = useGenericContext(TicketContext)
  const { refresh } = useGenericContext(FollowupContext)
  const getTicket = (dataBySocket: { ticket: ITicket }) => {
    setData(dataBySocket.ticket)
    refresh()
  }
  useSocket(getTicket, 'TicketSaved', `tickets.${data?.tkid}`)
  return (
    <>
      {!byData ? (
        <>
          <Title name={`${t('TICKET')} #${data?.tkid} - ${data?.title}`}>
            <TicketHead data={data} />
          </Title>
          <Divider />
        </>
      ) : null}
      <TicketContent />
      <TicketChecks />
      <Divider />
      <Followup />
      <UpDownButton />
    </>
  )
}

const filterColumns = (user: ISUser, data: ITicket | undefined) => {
  return ticketColumn.filter(column => {
    if (user.level === 1 && column.label === 'USERS') return false
    if (
      data &&
      data.rating &&
      data.rating.length === 0 &&
      column.label === 'RATING'
    )
      return false
    return true
  })
}

const TicketContent = () => {
  const { data } = useGenericContext(TicketContext)
  const { user } = useUser()
  const filteredColumns = filterColumns(user, data)

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {filteredColumns ? (
          <Descriptions
            columns={filteredColumns}
            dataSource={data}
            size="small"
            column={{ xs: 1, sm: 2, md: 2, lg: 6, xl: 6, xxl: 6 }}
            bordered
          />
        ) : null}
      </Col>
    </Row>
  )
}

const useTicketByState = ({ state }: { state: ITicket | undefined }) => {
  const [data, setData] = useState(state)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setData(state)
    setLoading(false)
  }, [state])

  return { data, setData, loading }
}

interface TicketByStateType {
  byData: boolean
  state: ITicket
}

const TicketByState = ({ state, byData }: TicketByStateType) => {
  const { data, setData, loading } = useTicketByState({ state })
  return (
    <TicketProvider {...{ data: data || state, setData, loading, byData }} />
  )
}

const useTicketByFetch = ({ tkid }: { tkid: string | number | undefined }) => {
  const [data, setData] = useState<ITicket | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<AxiosError | undefined>(undefined)

  useEffect(() => {
    api
      .get<ITicket>(`/ticket/${tkid}`)
      .then(e => {
        setData(e.data)
      })
      .catch((e: AxiosError) => {
        setError(e)
      })
      .finally(() => setLoading(false))
  }, [tkid])

  return { loading, data, setData, error }
}

const TicketProvider = ({
  data,
  setData,
  loading,
  error,
  byData
}: TicketContextType) => (
  <TicketContext.Provider value={{ data, setData, loading, error }}>
    <RenderPage data={data} loading={loading} error={error}>
      <TicketContainer byData={byData} />
    </RenderPage>
  </TicketContext.Provider>
)

const TicketProviderByFetch = ({ tkid }: { tkid?: string }) => {
  const { loading, data, setData, error } = useTicketByFetch({ tkid })
  return <TicketProvider {...{ data, setData, loading, error }} />
}

const useTicketByFetchWithContext = (tkid: string) => {
  const { data, setData } = useGenericContext(TicketContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<AxiosError | undefined>(undefined)

  useEffect(() => {
    api
      .get<ITicket>(`/ticket/${tkid}`)
      .then(e => setData(e.data))
      .catch((e: AxiosError) => setError(e))
      .finally(() => setLoading(false))
  }, [tkid])

  return { loading, data, setData, error }
}

const TicketByFetch = ({ tkid }: { tkid: string }) => {
  const { loading, data, error } = useTicketByFetchWithContext(tkid)
  return (
    <RenderPage data={data} loading={loading} error={error}>
      <TicketContainer byData />
    </RenderPage>
  )
}

const Ticket = ({ data }: { data?: ITicket | undefined }) => {
  const location = useLocation()
  const { tkid } = useParams()

  useEffect(() => {
    window.history.replaceState({}, '')
  }, [])

  if (data?.tkid) return <TicketContainer byData />

  if (typeof data === 'string') return <TicketByFetch tkid={data} />
  return (
    <FollowupProvider tkid={location?.state?.tkid || data?.tkid || tkid}>
      {location?.state || data ? (
        <TicketByState state={location?.state || data} byData={!!data} />
      ) : (
        <TicketProviderByFetch tkid={tkid} />
      )}
    </FollowupProvider>
  )
}

export default Ticket
