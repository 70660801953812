import { PropsWithChildren, useMemo } from 'react'
import { Drawer, Row } from 'antd'
import useModal from '@hooks/useModal'
import { BalanceContext } from '@contexts/BalanceContext'
import { IBalance } from '@/types/IBalance'
import Title from '@components/common/Title'
import { useTranslation } from 'react-i18next'
import Icon from '@components/common/Icon'
import { useTheme } from 'styled-components'
import ModalBalance from '@components/Balance/Modal'
import { IProductPod, IProductVm } from '@/types/IProduct'
import CardsPod from '../Card'
import { value_to_currency } from '../Modal'

export const BalanceHead = ({ data }: { data: IBalance }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Row style={{ width: '100%' }} justify="space-between">
      <Title
        name={`${t('BALANCE')}: ${value_to_currency(data.balance, 'pt-BR', 'BRL')}`}
        style={{ margin: 0, paddingTop: 10 }}
      />
      <ModalBalance
        type="primary"
        icon={<Icon name="far fa-dollar-sign" color="white" />}
        data-cy={`drawerEntity`}
        style={{ paddingInline: 47, background: theme.green }}
      >
        {t('ADDBALANCE')}
      </ModalBalance>
    </Row>
  )
}

interface BalanceDrawerProps extends PropsWithChildren {
  balance: IBalance
  numForm: string
  type: "pod" | "VM"
  data: IProductPod | IProductVm
}

const BalanceDrawer = ({ balance, data, children, numForm, type }: BalanceDrawerProps) => {
  const { open, onClose, onOpen } = useModal()

  const openDrawer = () => {
    onOpen()
  }

  const memoOpen = useMemo(
    () => ({ data, open, onClose, onOpen, openDrawer, numForm }),
    [data, open, onClose, onOpen, openDrawer, numForm]
  )

  return (
    <BalanceContext.Provider value={memoOpen}>
      <Drawer
        title={data ? <BalanceHead data={balance} /> : null}
        destroyOnClose
        width={'100vw'}
        {...{ ...{ open, onClose, onOpen } }}
      >
        <CardsPod onClose={onClose} data={data.data} type={type} />
      </Drawer>
      {children}
    </BalanceContext.Provider>
  )
}

export default BalanceDrawer
