import { Select } from "antd";
import useTicketSelect from "@hooks/useTicketSelect";
import FilterSelect from "@components/common/FilterSelect";
import useQueryItems from "@hooks/useQueryItems";
import useSearchParams from "@hooks/useSearchParams";
import { ISector } from "@/types/ISector";
import { useState, useEffect } from "react";

const queryItems = async (search = '', url: string) =>
  useQueryItems(search, 'sector');

const searchSector = async () => {
  return queryItems('', 'sector').then(sectors =>
    sectors.map((sector: ISector) => ({
      value: sector.slug,
      label: sector.name
    }))
  );
};

const NotAssignedSectorsSelect = () => {
  const params = useSearchParams(true);
  const { ...props } = useTicketSelect({ name: 'notAssignedSectors', func: searchSector, });

  const [notAssignedSectors, setNotAssignedSectors] = useState<string[]>([]);

  useEffect(() => {
    const notAssigned = params.getItem('notAssignedSectors');

    if (notAssigned) {
      const sectorsArray = notAssigned.split(',');
      if (JSON.stringify(sectorsArray) !== JSON.stringify(notAssignedSectors)) {
        setNotAssignedSectors(sectorsArray);
      }
    } else if (notAssignedSectors.length > 0) { setNotAssignedSectors([]) }
  }, [params, notAssignedSectors]);


  return (
    <FilterSelect
      placeholderKey="NOTASSIGNEDSECTOR"
      data-cy="filterNotAssignedSector"
      optionRenderer={({ value, label }) => (
        <Select.Option key={value} value={value}>{label}</Select.Option>)}
      {...props}
    />
  );
};

export default NotAssignedSectorsSelect;
