import { api } from '@helpers/api'
import { IOption } from '@/types/IOption'
import { Col, Form, message, Select, SelectProps } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SelectInputProps extends SelectProps {
  span: number
  label?: string
  constant: string
  required?: boolean
  inputName: string
  placeholder?: string
  onChange?: (value: string[]) => void
  multiple?: boolean
}

export const fetchConstants = async (constant: string, t: (key: string) => string) => {
  try {
    const response = await api.get('/constants')
    const options: IOption[] = Object.entries(response.data[constant]).map(
      ([key, value]) => ({
        value: String(value),
        label: t(String(value))
      })
    )
    return options
  } catch (error) {
    message.error(t('GETFORMDATAERROR'))
  }
}

export const SelectInput = ({
  span,
  label,
  constant,
  required,
  inputName,
  placeholder,
  onChange,
  multiple,
  ...props
}: SelectInputProps) => {
  const [data, setData] = useState<IOption[]>()
  const { t } = useTranslation()
  useEffect(() => {
    const fetchData = async () => {
      const options = await fetchConstants(constant, t)
      setData(options)
    }
    fetchData()
  }, [constant])

  return (
    <Col xs={{ span: 24 }} xl={{ span: span }}>
      <Form.Item
        name={inputName}
        label={label ? t(label) : ''}
        rules={[{ required: required, message: t('requiredItem') }]}
      >
        <Select
          mode={multiple ? 'multiple' : undefined}
          onChange={onChange}
          placeholder={placeholder ? t(placeholder) : t('CHOOSEANITEM')}
          allowClear
          options={data ? data.filter(option => option !== undefined) : []}
          data-cy="select-input"
          {...props}
        />
      </Form.Item>
    </Col>
  )
}
