import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import { convertKeysToTranslatedOptions } from '@helpers/convertKeysToOptions'
import { getGenericListsandFilter } from '@helpers/getGenericListsandFilter'
import { TICKETCOMPLEXITIES } from '@constants/ticketcomplexities'
import InputWithForm from '@common/InputWithForm'
import { DefaultOptionType } from 'antd/es/select'
import {
  Form,
  InputNumber,
  Row,
  Col,
  Input,
  Select,
  Segmented,
  FormItemProps
} from 'antd'
import RadioForAll from '@components/Ticket/RadioForAll'
import { TFunction } from 'i18next'
import { ServiceContext } from '@contexts/ServiceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { IsPrivateConfirm } from '@components/Ticket/TicketItemsForSupport'

const convertArrToOptions = (arr: Array<string>, t: TFunction) =>
  arr.map(item => ({ label: t(item), value: item }))

const TicketServiceComplexity = () => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
      <Form.Item
        data-cy="modal-service-complexity-select"
        name="complexity"
        label={t('COMPLEXITY')}
        rules={[{ required: true, message: t('requiredItem') }]}
      >
        <Select options={convertArrToOptions(TICKETCOMPLEXITIES, t)} />
      </Form.Item>
    </Col>
  )
}

const RequiredFormItem = (props: FormItemProps) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      {...props}
      rules={[{ required: true, message: t('requiredItem') }]}
    />
  )
}

const ResquestBySegment = () => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 6 }}>
      <Form.Item name="request_by" label={t('REQUESTBY')}>
        <Segmented
          options={[
            {
              label: t('CUSTOMER'),
              value: 'CUSTOMER'
            },
            {
              label: t('SUPPORT'),
              value: 'SUPPORT'
            }
          ]}
        />
      </Form.Item>
    </Col>
  )
}

const SectorSelect = () => {
  const { t } = useTranslation()
  const { service } = useGenericContext(ServiceContext)
  const [sectors, setSectors] = useState<DefaultOptionType[] | []>([])
  const getSectors = (e: string) =>
    getGenericListsandFilter(`sector?search=${e}&perPage=99`).then(e =>
      setSectors(e)
    )

  useEffect(() => {
    getSectors(service?.sector?.slug || '')
  }, [service])
  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <Form.Item name="sector" label={t('PRINCIPALSECTOR')} required={true}>
        <Select
          filterOption={false}
          showSearch
          allowClear
          onFocus={() => getSectors('')}
          onSearch={e => getSectors(e)}
          options={sectors}
          data-cy={'sector-selection'}
        />
      </Form.Item>
    </Col>
  )
}

const PrioritySelect = () => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 6 }}>
      <RequiredFormItem name="priority" label={t('PRIORITY')}>
        <Select
          data-cy="service-modal-select-priority"
          options={convertKeysToTranslatedOptions(
            TICKETSPRIORITIESCOLORS,
            t
          ).slice(0, -2)}
        />
      </RequiredFormItem>
    </Col>
  )
}

const ExpectedTimeSelect = () => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 6 }}>
      <RequiredFormItem name="expected_time" label={t('EXPECTEDTIME')}>
        <InputNumber
          placeholder={t('SENDTHETIMEINSECONDS')}
          min={0}
          style={{ width: '100%' }}
        />
      </RequiredFormItem>
    </Col>
  )
}

const CreateService = () => {
  const { t } = useTranslation()

  return (
    <Row style={{ width: '100%' }} gutter={[8, 8]}>
      <Col xs={{ span: 24 }} xl={{ span: 12 }}>
        <InputWithForm name="name" label={t('TITLE')} required />
      </Col>
      <ResquestBySegment />
      <SectorSelect />
      <PrioritySelect />
      <TicketServiceComplexity />
      <RadioForAll name="category" required />
      <ExpectedTimeSelect />
      <Col span={24}>
        <Form.Item name="description" label={t('DESCRIPTION')}>
          <Input.TextArea />
        </Form.Item>
      </Col>
      <IsPrivateConfirm service />
    </Row>
  )
}

export default CreateService
