import { useUser } from '@contexts/UserContext'
import { api } from '@helpers/api'
import useFetch from '@hooks/useFetch'
import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd'
import Meta from 'antd/es/card/Meta'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTheme } from 'styled-components'
import { MessageInstance } from 'antd/es/message/interface'
import { useTranslation } from 'react-i18next'
import { IBalance } from '@/types/IBalance'
import { FormInstance } from 'antd/lib'
import { value_to_currency } from '@components/Pod/Modal'

interface Balance {
  uuid: string
  price: number
}

interface OnFinishParams {
  url: string
  companyUuid: string
  messageApi: MessageInstance
  onCancel: () => void
  voucher: string
  balanceId: string
  setBalance: Dispatch<SetStateAction<IBalance>>
  t: (key: string) => string
}

const onFinish = async ({
  url,
  companyUuid,
  messageApi,
  onCancel,
  voucher,
  balanceId,
  setBalance,
  t
}: OnFinishParams) => {
  const company = {
    company: companyUuid
  }

  const voucherString = {
    company: companyUuid,
    voucher: voucher
  }

  if (voucher !== '') {
    api
      .post(url, voucherString)
      .then(async response => {
        if (response?.status === 200) {
          messageApi.success(t('SUCCESSREQUEST'), 2)
          const balance = await api.get('/balance')
          setBalance(balance.data)
          onCancel()
          return response.data || { success: true }
        }
      })
      .catch(() => {
        messageApi.error(t('INVALIDVOUCHER'))
      })
  } else {
    api
      .post(`${url}/${balanceId}`, company)
      .then(response => {
        if (response?.status === 200) {
          window.location.href = response.data.redirect
          return response.data || { success: true }
        }
      })
      .catch(() => {
        messageApi.error(t('ERRORPAYMENT'))
      })
  }
}

interface ModalStep {
  step: boolean
  setStep: (step: boolean) => void
  onCancel: () => void
  numForm: string
  form: FormInstance
}

const FormBalance = ({ step, setStep, onCancel, numForm, form }: ModalStep) => {
  const { setBalance } = useUser()
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const { user } = useUser()
  const theme = useTheme()
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null)
  const [companyUuid, setCompanyUuid] = useState<string>(user.companies[0].uuid)
  const [voucher, setVoucher] = useState<string>('')

  const { data } = useFetch<Balance | null, unknown>({
    func: () => api.get(`/balance/add`)
  })

  const handleChange = (value: string) => {
    setCompanyUuid(value)
  }

  const handleCardClick = (
    data: Balance,
    companyUuid: string,
    messageApi: MessageInstance
  ) => {
    setSelectedAmount(data.price)
    message.open({
      type: 'loading',
      content: t('PROCESSINGPAYMENT')
    })
    onFinish({
      url: '/balance/add',
      companyUuid,
      messageApi,
      onCancel,
      voucher,
      balanceId: data.uuid,
      setBalance,
      t
    })
  }

  const handleVoucher = () => {
    message.open({
      type: 'loading',
      content: t('PROCESSINGVOUCHER'),
      duration: 2
    })

    onFinish({
      url: '/balance/voucher',
      companyUuid,
      messageApi,
      onCancel,
      voucher,
      balanceId: '0',
      setBalance,
      t
    })
  }

  return (
    <Form
      name="autofill"
      layout="vertical"
      initialValues={{ companyUuid: user.companies[0].uuid, voucher: '' }}
      form={form}
      onFinish={handleVoucher}
      id={numForm}
    >
      {contextHolder}
      <Row
        gutter={[12, 12]}
        style={{
          paddingBottom: '10px'
        }}
      >
        <Col xs={24} xl={12}>
          <Form.Item name={'companyUuid'} label={t(`COMPANY`)}>
            <Select
              onChange={handleChange}
              defaultValue={user.companies[0].uuid}
              options={user.companies.map(company => ({
                value: company.uuid,
                label: company.name
              }))}
            />
          </Form.Item>
        </Col>

        {step ? (
          <Col xs={24} xl={12}>
            <Form.Item
              name={'voucher'}
              label={t(`Voucher`)}
              rules={[
                {
                  required: true,
                  message: t('requiredItem')
                }
              ]}
            >
              <Input
                name="voucher"
                placeholder={t('Voucher')}
                data-cy={'voucher-input'}
                onChange={e => setVoucher(e.target.value)}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col
            xs={24}
            xl={{
              span: 6,
              offset: 6
            }}
          >
            <Button
              style={{
                width: '100%'
              }}
              type="primary"
              onClick={() => setStep(true)}
              data-cy={'add-voucher'}
            >
              {t('ADDVOUCHER')}
            </Button>
          </Col>
        )}
      </Row>
      <Row justify={'end'}>
        {step ? (
          <>
            <Button
              onClick={() => {
                setStep(false)
                setVoucher('')
              }}
              danger
              type="primary"
              style={{
                marginRight: '10px'
              }}
              data-cy={'back'}
            >
              {t('BACK')}
            </Button>
            <Button
              type="primary"
              data-cy={'submit'}
              htmlType="submit"
              key="submit"
            >
              {t('CONFIRM')}
            </Button>
          </>
        ) : (
          <Row gutter={[16, 16]} justify={'center'}>
            {data.map((data: Balance) => (
              <div
                key={data.uuid}
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px'
                }}
              >
                <Card
                  hoverable
                  style={{
                    width: 240,
                    backgroundColor:
                      selectedAmount === data.price
                        ? theme.green
                        : theme.background,
                    transition: 'background-color 0.3s ease'
                  }}
                  onClick={() => handleCardClick(data, companyUuid, messageApi)}
                >
                  <Meta
                    title={`${value_to_currency(data.price, 'pt-BR', 'BRL')}`}
                  />
                </Card>
              </div>
            ))}
          </Row>
        )}
      </Row>
    </Form>
  )
}

export default FormBalance
