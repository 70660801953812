import { useRef, useState, useCallback, useEffect } from 'react'
import { Tag as AntTag, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import {
  SyncOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'
import { api } from '@helpers/api'
import { Modal } from 'antd'
import useSocket from '@hooks/useSocket'
import { ITicket } from '@/types/ITicket'

interface SocketMensage {
  calledBy: string
  mode: string
  ticket: ITicket
}

const OverviewAudio = () => {
  const [open, setOpen] = useState(true)
  const ref = useRef<HTMLAudioElement>()

  const playSound = (data: SocketMensage) => { // @NO_SONAR_START@
    if (data.ticket.status === "NEW" && data.ticket.category.slug === "incident") { // @NO_SONAR_START@
      ref.current?.play()
    } // @NO_SONAR_END@
  } // @NO_SONAR_END@

  useSocket(playSound, 'TicketSaved', `tickets.tv`)

  return (
    <>
      <Modal
        {...{ open, onCancel: () => setOpen(false) }}
        footer={null}
        title="User Iteration"
      />
      <audio ref={ref} volume={1} src="/assets/audios/calling.ogg" />
    </>
  )
}
const Tag = ({ children, ...props }) => {
  return (
    <AntTag
      {...props}
      style={{
        lineHeight: 2,
        fontSize: 30,
        justifyContent: 'center',
        display: 'flex',
        borderRadius: '8px'
      }}
    >
      {children}
    </AntTag>
  )
}
const useOverviewCounter = () => {
  const { search } = useLocation()
  const [data, setData] = useState({
    NEW: 0,
    PROCESSING_ATTRIBUTED: 0,
    PROCESSING_PLANNED: 0,
    PENDING: 0,
    SOLVED: 0
  })
  const queryCounter = useCallback(async () => {
    await api
      .get(`/ticket/count${search}`)
      .then((e: any) => {
        setData(e.data)
      })
  }, [search])

  useEffect(() => {
    queryCounter()
  }, [search])

  useSocket(queryCounter, 'TicketSaved', `tickets.tv`)

  return { data }
}

const COUNTER_TAGS = [
  {
    test: 'tag-new',
    color: 'blue-inverse',
    icon: <InfoCircleOutlined />
  },
  {
    test: 'tag-processing',
    color: '#f50',
    icon: <SyncOutlined spin />
  },
  {
    test: 'tag-pending',
    color: '#edb007',
    icon: <ClockCircleOutlined />
  },
  {
    test: 'tag-solved',
    color: 'green-inverse',
    icon: <CheckCircleOutlined />
  }
]

const CounterTag = ({ data, children }) => {
  return (
    <Tag data-cy={data.test} color={data.color} icon={data.icon}>
      {children}
    </Tag>
  )
}

const OverviewCounter = () => {
  const { data } = useOverviewCounter()
  return (
    <>
      {data ? (
        <Space>
          <OverviewAudio />
          <CounterTag data={COUNTER_TAGS[0]}>{' ' + data.NEW}</CounterTag>
          <CounterTag data={COUNTER_TAGS[1]}>
            {data.PROCESSING_ATTRIBUTED + data.PROCESSING_PLANNED}
          </CounterTag>
          <CounterTag data={COUNTER_TAGS[2]}> {' ' + data.PENDING}</CounterTag>
          <CounterTag data={COUNTER_TAGS[3]}>{data.SOLVED}</CounterTag>
        </Space>
      ) : null}
    </>
  )
}

export default OverviewCounter
