import { Key } from 'react'
import { CustomDescriptionType } from '@/types/IDescriptions'
import { ICompany } from '@/types/ICompany'
import { useTranslation } from 'react-i18next'
import AvatarSprite from '@components/common/AvatarSprite'
import {
  Tag as AntTag,
  Card,
  Space,
  Rate,
  Typography,
  Flex,
  Tooltip,
  Button
} from 'antd'
import Tag from '@components/common/Tag'
import useFetch from '@hooks/useFetch'
import { queryFetch } from '@pages/Ticket'
import { SyncOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import { IRequestBy, ITicket } from '@/types/ITicket'
import { IUser } from '@/types/IUser'
import StatusPriorityTag from '../StatusPriorityTag'
import TicketTime from '../Time'
import { humanizeDurationsCompact } from '@helpers/humanizeDurations'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import { DeviceDropdownOrTag } from '@components/Device/DropdownDevice'
import { IRating } from '@/types/IRating'
import TextMarkdown from '@components/common/TextMarkdown'
import Icon from '@components/common/Icon'
import { REQUESTBYICONS } from '@constants/requestbyicons'

const ServiceTag = ({ ticket }: { ticket: ITicket }) => {
  const { t } = useTranslation()
  const color = ticket.priority
    ? TICKETSPRIORITIESCOLORS[ticket.priority].color
    : 'blue-inverse'
  if (ticket.service === null) return null
  return (
    <Tooltip title={`${t('PRIORITY')}: ${t(ticket.priority)}`}>
      <AntTag color={color}>
        {ticket?.service?.name ? `${ticket?.service?.name}` : null}
        {ticket.expected_time
          ? ` - ${humanizeDurationsCompact(ticket.expected_time)}`
          : null}
      </AntTag>
    </Tooltip>
  )
}
const Rating = ({ rating }: { rating: IRating }) => {
  return (
    <>
      {rating?.rate ? (
        <Space>
          <Card
            title={rating.user.name}
            variant="borderless"
            style={{ maxWidth: 300 }}
          >
            <Space direction="vertical">
              <Rate disabled defaultValue={rating.rate} />
              <Typography.Text>
                {rating.comment ? `"${rating.comment}"` : null}
              </Typography.Text>
            </Space>
          </Card>
        </Space>
      ) : null}
    </>
  )
}

export const ticketColumn: CustomDescriptionType<ITicket>[] = [
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'TIME',
    key: 'tkid',
    render: ({ item }: { item: ITicket }) => <TicketTime data={item} />
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'COMPANY',
    key: 'company',
    render: ({ data }) => {
      return <>{data.name}</>
    }
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'STATUS',
    key: 'status',
    render: ({ data }) => (
      <StatusPriorityTag {...{ data, obj: TICKETSTATUSESCOLORS }} />
    )
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'REQUESTBY',
    key: 'request_by',
    render: ({ data }: { data: IRequestBy | null }) => (
      <AntTag color="blue-inverse">
        <Space>
          {data ? <Icon color="white" name={REQUESTBYICONS[data]} /> : null}
          {data}
        </Space>
      </AntTag>
    )
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'CATEGORY',
    key: 'category',
    render: ({ data }: { data: ITicket['category'] }) => (
      <span>{data?.name ? data.name : null}</span>
    )
  },
  {
    span: { sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 },
    label: 'SERVICE',
    key: 'service',
    render: ({ _, item }) => <ServiceTag ticket={item} />
  },
  {
    span: { sm: 2, md: 2, lg: 6, xl: 6, xxl: 6 },
    label: 'DEVICES',
    key: 'devices',
    render: ({ data }: { data: ITicket['devices'] }) => {
      return (
        <Space
          style={{ display: 'flex', flexWrap: 'wrap', width: '0.9rem' }}
          wrap
        >
          {data
            ? data.map(device => (
              <DeviceDropdownOrTag
                key={device.device.uuid}
                device={device.device}
              />
            ))
            : null}
        </Space>
      )
    }
  },
  {
    span: { lg: 6, xl: 6, xxl: 6 },
    label: 'USERS',
    key: 'company',
    render: ({ data, item }) => {
      const result = useFetch<ICompany, string>({
        func: queryFetch,
        params: `/company/${data.uuid}`,
        deps: [item.tkid]
      })
      const { t } = useTranslation()

      if (item?.is_private) {
        return <span>{t('THISTICKETISPRIVATE')}</span>
      }
      if (data?.name === 'OPEN DATACENTER') {
        return <span>OPEN DATACENTER</span>
      }

      if (result.loading) {
        return <Tag icon={<SyncOutlined spin />} color="blue-inverse" />
      }

      return (
        <>
          <Space wrap>
            {data?.name !== 'OPEN DATACENTER' && result.data.users
              ? result.data.users.map((e: IUser, index: Key) => (
                <Tooltip title={e.email} key={index}>
                  <Button key={e.email} type="primary" size="small">
                    {e?.name}
                  </Button>
                </Tooltip>
              ))
              : null}
          </Space>
        </>
      )
    }
  },
  {
    ...{
      span: { lg: 2, xl: 2, xxl: 2 },
      label: 'ASSIGNEDTO',
      key: 'suser'
    },
    render: ({ data }) => <span>{data ? data.name : null}</span>
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'SECTOR',
    key: 'sector',
    render: ({ data }) => <span>{data ? data.name : null}</span>
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'CREATEDAT',
    key: 'created_at',
    render: ({ data }) => dayjs(data).format(DATEFORMAT)
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'UPDATEDAT',
    key: 'updated_at',
    render: ({ data }) => {
      return <>{data ? dayjs(data).format(DATEFORMAT) : null}</>
    }
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'SCHEDULEDAT',
    key: 'schedule_at',
    render: ({ data }) => (data ? dayjs(data).format(DATEFORMAT) : null)
  },
  {
    span: { lg: 2, xl: 2, xxl: 2 },
    label: 'CREATEBY',
    key: 'created_by',
    render: ({ data }) => {
      return <>{data ? <AvatarSprite user={data} /> : null}</>
    }
  },
  {
    span: { lg: 6, xl: 6, xxl: 6 },
    label: 'CONTENT',
    key: 'content',
    render: ({ data }) => {
      return (
        <Typography.Paragraph>
          <TextMarkdown text={data} />
        </Typography.Paragraph>
      )
    }
  },
  {
    span: { lg: 6, xl: 6, xxl: 6 },
    label: 'RATING',
    key: 'rating',
    render: ({ data }: { data: ITicket['rating'] }) => {
      return (
        <Flex wrap="wrap">
          {data
            ? data.map(rating => <Rating rating={rating} key={rating.uuid} />)
            : null}
        </Flex>
      )
    }
  }
]
