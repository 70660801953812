
import FilterSelect from "@components/common/FilterSelect";
import useTicketSelect from "@hooks/useTicketSelect";
import useQueryItems from "@hooks/useQueryItems";
import { Select } from "antd";
import { IUser } from "@/types/IUser";

const search = async (search = '') =>
  useQueryItems(search, 'user');

const optionRenderer = (e: IUser) => (
  <Select.Option key={e.uuid} value={e.uuid}>
    {e.name}
  </Select.Option>
);

const CreatedbySelect = () => {
  const { options, ...props } = useTicketSelect({
    name: 'created_by',
    func: search,
  });

  return (
    <FilterSelect
      allowClear
      {...props}
      options={options || []}
      placeholderKey="CREATEDBY"
      data-cy="createdby-filter"
      optionRenderer={optionRenderer}
    />
  );
};

export default CreatedbySelect;
