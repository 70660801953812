import Title from '@common/Title'
import { useTranslation } from 'react-i18next'
import { Row, Tabs } from 'antd'
import { renderingTabFilter } from '@components/Server/Table'
import type { TabsProps } from 'antd'
import CompaniesSelect from '@components/common/CompaniesSelect'
import CreateServer from '@components/Server/CreateServer'
import SearchByQueryInput from '@components/common/SearchByQueryInput'
import { DeviceContext } from '@contexts/DeviceContext'
import { useEffect, useMemo, useState } from 'react'
import { IDevice } from '@/types/IDevice'
import Device from '@pages/Device'
import DrawerProvider from '@contexts/DrawerContext'
import DeviceHead from '@components/Device/DeviceHead'
import { SelectInput, fetchConstants } from '@components/common/SelectInput'
import { IOption } from '@/types/IOption'
import ParamsSelect from '@components/common/ParamsSelect'
import useSearchParams from '@hooks/useSearchParams'

const Servers = () => {
  const [data, setData] = useState<IDevice | undefined>(undefined)
  const [option, setOption] = useState<IOption[]>()
  const { t } = useTranslation()
  const [deviceType, setDeviceType] = useState<string[]>(['BAREMETAL'])
  const renderingWithTab: TabsProps['items'] = renderingTabFilter(
    `device?type=${deviceType}`
  )
  const params = useSearchParams()
  const contextValue = useMemo(() => ({ data, setData }), [data, setData])

  useEffect(() => {
    const fetchData = async () => {
      const options = await fetchConstants('DEVICE_BAREMETAL_POOLS', t)
      if (options) {
        setOption(options)
      }
    }
    fetchData()
  }, [])

  const removeParams = () => {
    if (!deviceType.includes("BAREMETAL")) {
      params.removeItem("pool");
    }
  };

  useEffect(() => {
    removeParams();
  }, [deviceType]);

  const handleSelectChange = (value: string[]) => {
    if (value.length === 0) {
      setDeviceType(['BAREMETAL']);
    } else {
      setDeviceType(value);
    }
  };

  return (
    <DeviceContext.Provider value={contextValue}>
      <DrawerProvider component={Device} head={DeviceHead}>
        <Title name={t('SERVERS')} />
        <Row gutter={[6, 6]}>
          <CompaniesSelect span={4} />
          <SelectInput
            inputName="type"
            span={4}
            constant={'DEVICE_TYPES'}
            placeholder="FILTERBYDEVICE"
            onChange={handleSelectChange}
            multiple
          />
          {deviceType.includes("BAREMETAL") &&
            <ParamsSelect options={option?.map(opt => opt.value).filter((value): value is string => typeof value === 'string') || []} param="pool" />
          }
          <SearchByQueryInput span={4} />
          <CreateServer />
        </Row>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="1"
          items={renderingWithTab}
          type="card"
          size="large"
        />
      </DrawerProvider>
    </DeviceContext.Provider>
  )
}

export default Servers
